import React, { useContext, useEffect, useState } from 'react'
import './Profile.css'
import { Images } from '../Images/Images'
import { ButtonComponent } from '../../Utils/Helper/HelperFunction'
import Pagination, { PaginatedComponent } from '../Pagination/Pagination'
import { useNavigate } from 'react-router-dom'
import GetRequest from '../../services/GetRequest'
import { PATHS, SERVICES } from '../../config/Api_Url'
import { BetContext } from '../Context/UseContext'

export const Profile = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState('currentBets');
    const navigate = useNavigate();
    const { isAuthenticatedWith, profile, setProfile } = useContext(BetContext)
    const betsData = [
        {
            id: 1,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 2,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 3,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 4,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 5,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 6,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 7,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 8,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 9,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 10,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 11,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 12,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 13,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Ongoing'
        },
        {
            id: 14,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 15,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 16,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 17,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 18,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 19,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
        {
            id: 20,
            eventTitle: 'Jake Paul vs. Mike Tyson: Who will win?',
            betPlaced: '12/08/2024',
            odds: '22%',
            bet: '₱1,000',
            potentialPayout: '₱2,500',
            status: 'Pending'
        },
    ];

    const itemsPerPage = 10;
    const totalPages = Math.ceil(betsData.length / itemsPerPage);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const currentBets = betsData.slice(startIdx, endIdx);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getProfile = async () => {
        try {

            let profile = await GetRequest(SERVICES.accountService, PATHS.profile);
            setProfile(profile.data.data);

        } catch (error) {
            console.log('profile error: ', error);
        }

    }

    useEffect(() => {
        getProfile()
    }, [isAuthenticatedWith])

    return (
        <div>
            <div className="profile-main-div mt-3">
                <div className='profile-one'>
                    <div className='profile-img-he'>

                        <div className='img-pro'>
                            <img src={profile?.image || Images.profile.profileImg} alt="profile-image" />
                        </div>
                        <div className='content'>
                            <div className='name'>{profile?.username || 'John wick'}</div>
                            <div className='par'>Email : {profile?.email || 'johnwick@gmail.com'} </div>
                            <div className='par'>Joined On : 24/06/2023</div>
                        </div>
                    </div>
                    <div>
                        <div onClick={() => navigate('/settings')} className='profile-edit-div'>
                            <div className='profile-edit-icon'>
                                <img src={Images.profile.editIcon} alt="edit icon" />
                            </div>
                            <div className='text'>Edit Profile</div>
                        </div>
                    </div>
                </div>

                <div className="profile-two">
                    <div className='avail-bal'>
                        <div className="txt">
                            Available Balance
                        </div>
                        <div className='bal'>
                            ₱12,500
                        </div>
                    </div>
                    <div className='avail-bal-btns'>
                        <ButtonComponent
                            style={{
                                background: 'rgba(228, 181, 40, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRadius: '6px',
                                fontSize: '14px',
                                padding: '10px 35px',
                                border: 'unset'
                            }}
                            text={'Deposit'} />
                        <ButtonComponent
                            style={{
                                background: 'rgba(28, 121, 71, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRadius: '6px',
                                fontSize: '14px',
                                padding: '10px 35px',
                                border: 'unset'
                            }}
                            text={'Withdraw'} />
                    </div>
                </div>


                <div className="pro-car">
                    <div className="card-one">
                        <div className='avail-bal'>
                            <div className="txt">
                                Total Bets
                            </div>
                            <div className='bal'>
                                57567
                            </div>
                        </div>
                        <div className='total-bets-img' >
                            <img src={Images.profile.totalBets} alt="total bets" />
                        </div>
                    </div>
                    <div className="card-two">
                        <div className='avail-bal'>
                            <div className="txt">
                                Total Wons
                            </div>
                            <div className='bal'>
                                57567
                            </div>
                        </div>
                        <div className='total-bets-img' >
                            <img src={Images.profile.totalWon} alt="total wons" />
                        </div>
                    </div>
                </div>

                <div className="pro-tab">
                    <div className="table-container">
                        <div className='tab-hed'>
                            <div onClick={() => setIsActive('currentBets')} className={`tab-hed-item ${(isActive === 'currentBets') ? 'active' : ''}`}>
                                Current Bets
                            </div>
                            <div onClick={() => setIsActive('bettingHistory')} className={`tab-hed-item ${(isActive === 'bettingHistory') ? 'active' : ''}`}>
                                Betting History
                            </div>
                            <div onClick={() => setIsActive('transactions')} className={`tab-hed-item ${(isActive === 'transactions') ? 'active' : ''}`}>
                                Transaction
                            </div>
                        </div>
                        <table className='mt-3' id="employee-table">
                            <thead>
                                <tr>
                                    <th onClick={() => ('eventTitle')}>Event Title</th>
                                    <th onClick={() => ('betPlaced')}>Bet Placed</th>
                                    <th onClick={() => ('odds')}>Odds</th>
                                    <th onClick={() => ('bet')}>Bet</th>
                                    <th onClick={() => ('potentialPayout')}>Potential Payout</th>
                                    <th onClick={() => ('status')}>Status</th>
                                </tr>
                            </thead>
                            <tbody id="table-body">
                                {currentBets.map(bet => (
                                    <tr key={bet.id}>
                                        <td>{bet.eventTitle}</td>
                                        <td>{bet.betPlaced}</td>
                                        <td>{bet.odds}</td>
                                        <td>{bet.bet}</td>
                                        <td>{bet.potentialPayout}</td>
                                        <td >
                                            <div className={`${bet.status} dot-div`}>
                                                <span className='img-sp'>
                                                    <img src={bet.status === 'Pending' ? Images.profile.yelloDot : Images.profile.greenDot} alt="dot-img" />
                                                </span>
                                                <span>
                                                    {`${bet.status}`}
                                                </span>

                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <PaginatedComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    )
}
