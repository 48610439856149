import React, { useContext, useEffect, useState } from 'react'
import { json, Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../../Utils/ProtectedRoute';
import LoginPopup from '../Login/LoginPopup';
import { Home } from '../../Pages/Home/Home';
import axios from 'axios';
import { BetContext } from '../Context/UseContext';
import { useSessionStorageData } from '../../Utils/Helper/HelperFunction';
import PostRequest from '../../services/PostRequest';
import { useAuth0 } from '@auth0/auth0-react';
import auth0Config from '../../Utils/auth0-config';
import { Sports } from '../../Pages/Sports/Sports';
import { ActiveBets } from '../../Pages/ActiveBets/ActiveBets';
import { Politics } from '../../Pages/Politics/Politics';
import { Entertainment } from '../../Pages/Entertainment/Entertainment';
import { Weather } from '../../Pages/Weather/Weather';
import { CardDetails } from '../CardDetails/CardDetails';
import { PATHS, SERVICES } from '../../config/Api_Url';
import { Profile } from '../Profile/Profile';
import { Settings } from '../Settings/Settings';
import { Favourite } from '../../Pages/Favourite/Favourite';
import { MyWallet } from '../MyWallet/MyWallet';

export const RoutesAndLayouts = () => {

    let sessionStorageData = useSessionStorageData();

    const { isAuthenticated: googleIsAuthenticated, getAccessTokenSilently, user } = useAuth0();

    const { isAuthenticatedWith, setIsAuthenticatedWith } = useContext(BetContext);

    const handleSocialLogin = async () => {
        try {

            let token = await getAccessTokenSilently()
            console.log('token: ', token);
            
            sessionStorage.setItem('google-access-token', JSON.stringify(token));

            let body = {
                token: token,
                username: user.name
            }
            let res = await PostRequest(SERVICES.accountService, PATHS.socialLogin, body)

            setIsAuthenticatedWith({
                normal: false,
                social: true
            })
            console.log('social-login res', res);
        } catch (error) {
            console.log(error);
        }
    }

    const checkAuthState = async () => {
        if (googleIsAuthenticated) {
            await handleSocialLogin();
        } else if (sessionStorageData.apiUserData?.tokens?.access?.token) {
            setIsAuthenticatedWith({
                normal: true,
                social: false
            });
        } else {
            setIsAuthenticatedWith({
                normal: false,
                social: false
            });
        }
    };


    useEffect(() => {
        checkAuthState();

    }, [googleIsAuthenticated, sessionStorageData.apiUserData?.tokens?.access?.token]);


    // const publicRoutes = [
    //     {
    //         path: 'login',
    //         element: <LoginPopup onLogin={login} />
    //     }
    // ]

    const protectedRoute = [
        {
            path: 'home/:category',
            element: <Home />,
        },
        {
            // path: 'event-detail/:category/:page/:id',
            path: ':page/:category/event-detail/:id',
            element: <CardDetails />
        },
        {
            path: 'active-bets/:category',
            element: <ActiveBets />,
        },
        {
            path: 'sports/:category',
            element: <Sports />,
        },
        {
            path: 'politics/:category',
            element: <Politics />,
        },
        {
            path: 'entertainment/:category',
            element: <Entertainment />,
        },
        {
            path: 'weather/:category',
            element: <Weather />,
        },
        {
            path: 'profile/',
            element: <Profile />,
        },
        {
            path: 'settings',
            element: <Settings />,
        },
        {
            path: 'favourite',
            element: <Favourite />
        },
        {
            path: 'my-wallet',
            element: <MyWallet />
        },

    ]


    return (
        <Routes>
            {/* Public Routes */}
            {/* {publicRoutes.map((route, key) => <Route path={route.path} element={route.element} key={key} />)} */}
            {/* Redirect root path ("/") to "/home/all" */}
            <Route path="/" element={<Navigate to="/home/all" />} />

            {/* Protected Routes */}
            <Route path='/' element={<ProtectedRoute />} >
                {
                    protectedRoute.map((route, key) => {
                        return <Route path={route.path} element={route.element} key={key} />
                    })
                }
            </Route>

            {/* Redirect to login if no route matches */}
            <Route path="*" element={<Navigate to={(isAuthenticatedWith.social || isAuthenticatedWith.normal) ? "/home/all" : "/home/all"} />} />
        </Routes>
    );
};


