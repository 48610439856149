import React, { useEffect, useState } from 'react'
import './CardDetails.css'
import { Images } from '../Images/Images'
import RadialBarChart from '../RadialBarChart/RadialBarChart'
import { ButtonComponent, capitalizeFirstLetter, formatDate, getTruncatedText, LoaderComponent } from '../../Utils/Helper/HelperFunction'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import GetRequest from '../../services/GetRequest'
import { PATHS, SERVICES } from '../../config/Api_Url'
import PostRequest from '../../services/PostRequest'
import { toast } from 'react-toastify'

export const CardDetails = ({ isActiveBetPage }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [cardDetailData, setCardDetailData] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const { eventData } = location.state || {};
    const [selectedBetDetail, setSelectedBetDetail] = useState({
        optionID: eventData?.optionID ,
        isNegative: eventData?.isNegative,
        isPositive: eventData?.isPositive,
        betAmount: '',
        selectedOptionDetail: eventData?.selectedOptionDetail
    });


    const goBack = () => navigate(-1);
    const { page, id: cardId } = useParams();

    const commentsData = [
        {
            username: 'karl84',
            date: '7 May 2024',
            comment: `Jake Paul has done absolutely nothing in his boxing “career” to earn a fight with a legitimate HOF boxer. Paul is an arrogant unskilled influencer who insults the sport of boxing constantly. Paul says he is the best, and talks hella shit but can’t back it up.`,
            commentCount: 3,
            likeCount: 5
        },
        {
            username: 'alex12',
            date: '8 May 2024',
            comment: `Jake Paul’s record may not show it, but he’s putting in the effort. Love him or hate him, you can't ignore his presence.`,
            commentCount: 2,
            likeCount: 8
        },
        {
            username: 'karl84',
            date: '7 May 2024',
            comment: `Jake Paul has done absolutely nothing in his boxing “career” to earn a fight with a legitimate HOF boxer. Paul is an arrogant unskilled influencer who insults the sport of boxing constantly. Paul says he is the best, and talks hella shit but can’t back it up.`,
            commentCount: 3,
            likeCount: 5
        },
        {
            username: 'alex12',
            date: '8 May 2024',
            comment: `Jake Paul’s record may not show it, but he’s putting in the effort. Love him or hate him, you can't ignore his presence.`,
            commentCount: 2,
            likeCount: 8
        },
        {
            username: 'karl84',
            date: '7 May 2024',
            comment: `Jake Paul has done absolutely nothing in his boxing “career” to earn a fight with a legitimate HOF boxer. Paul is an arrogant unskilled influencer who insults the sport of boxing constantly. Paul says he is the best, and talks hella shit but can’t back it up.`,
            commentCount: 3,
            likeCount: 5
        },
        {
            username: 'alex12',
            date: '8 May 2024',
            comment: `Jake Paul’s record may not show it, but he’s putting in the effort. Love him or hate him, you can't ignore his presence.`,
            commentCount: 2,
            likeCount: 8
        },
        {
            username: 'karl84',
            date: '7 May 2024',
            comment: `Jake Paul has done absolutely nothing in his boxing “career” to earn a fight with a legitimate HOF boxer. Paul is an arrogant unskilled influencer who insults the sport of boxing constantly. Paul says he is the best, and talks hella shit but can’t back it up.`,
            commentCount: 3,
            likeCount: 5
        },
        {
            username: 'alex12',
            date: '8 May 2024',
            comment: `Jake Paul’s record may not show it, but he’s putting in the effort. Love him or hate him, you can't ignore his presence.`,
            commentCount: 2,
            likeCount: 8
        },
        {
            username: 'karl84',
            date: '7 May 2024',
            comment: `Jake Paul has done absolutely nothing in his boxing “career” to earn a fight with a legitimate HOF boxer. Paul is an arrogant unskilled influencer who insults the sport of boxing constantly. Paul says he is the best, and talks hella shit but can’t back it up.`,
            commentCount: 3,
            likeCount: 5
        },
        {
            username: 'alex12',
            date: '8 May 2024',
            comment: `Jake Paul’s record may not show it, but he’s putting in the effort. Love him or hate him, you can't ignore his presence.`,
            commentCount: 2,
            likeCount: 8
        },
        {
            username: 'karl84',
            date: '7 May 2024',
            comment: `Jake Paul has done absolutely nothing in his boxing “career” to earn a fight with a legitimate HOF boxer. Paul is an arrogant unskilled influencer who insults the sport of boxing constantly. Paul says he is the best, and talks hella shit but can’t back it up.`,
            commentCount: 3,
            likeCount: 5
        },
        {
            username: 'alex12',
            date: '8 May 2024',
            comment: `Jake Paul’s record may not show it, but he’s putting in the effort. Love him or hate him, you can't ignore his presence.`,
            commentCount: 2,
            likeCount: 8
        },
    ];
    const [commentsToShow, setCommentsToShow] = useState(1); // Initially showing 1 comment
    const [allCommentsShown, setAllCommentsShown] = useState(false);

    const handleLoadMore = () => {
        if (commentsToShow >= commentsData.length) {
            setAllCommentsShown(true); // When all comments are shown, hide "Load More"
        } else {
            setCommentsToShow(prev => prev + 1); // Show one more comment each time
        }
    };

    const getCardDetailData = async (_Id) => {
        try {

            let detail = await GetRequest(SERVICES.eventService, `${PATHS.eventDetail}/${_Id}`);
            setCardDetailData(detail?.data?.data)
        } catch (error) {
            console.log('detail error: ', error);
        }
    }
    useEffect(() => {
        getCardDetailData(cardId)
    }, [cardId])


    console.log('eventData:', eventData);
    console.log('cardDetailData:', cardDetailData);
    console.log('selectedBetDetail:', selectedBetDetail);

    const handleSelectedBetDetail = ({ optionID, isPositive, isNegative, betAmount, selectedOptionDetail }) => {
        setSelectedBetDetail(prev => ({
            ...prev,
            optionID: optionID,
            isPositive: isPositive,
            isNegative: isNegative,
            betAmount: betAmount,
            selectedOptionDetail: selectedOptionDetail
        }));
    };

    const clickPlaceBet = async () => {
        setIsLoading(true)
        try {
            let body = {
                event: cardId,
                option: selectedBetDetail.optionID,
                amount: selectedBetDetail.betAmount
            }
            let placed_bet = await PostRequest(SERVICES.bettingService, PATHS.place_bet, body);

            console.log('placed_bet:', placed_bet);

            toast.success('Your bet have been placed successfully');
        } catch (error) {

            console.log('error:', error);

            toast.error(error?.response?.data?.message);
            console.log('clickPlaceBet error: ', error);

        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='row  card-detail-main'>
            <div className='col-md-8  card-detail-home'>
                <div className='cards-deatil-head'>
                    <div onClick={() => goBack()} className='card-back-div'><img src={Images.cards.cardDetailBack} alt="card-back-btn" /></div>
                    <div className='card-det-pro-img'>
                        <img src={cardDetailData.image || Images.cards.imageOne} alt="" />
                    </div>
                    <div className='card-det-head-con'>
                        <div className='det-txt'>{cardDetailData.title || 'Jake Paul vs. Mike Tyson: Who will win?'}</div>
                        <div className='detail-foter-icon'>
                            <div className='det-timer-main'>
                                <div className='detail-footer'>
                                    <div className='park-img add-mar'>
                                        <img src={Images.cards.parking} alt="parking image" />
                                    </div >
                                    <div className='card-det-count'>{cardDetailData.total_pool}  Bet</div>
                                </div>
                                <div className='px-2 detail-footer'>
                                    <div className='park-img add-mar'>
                                        <img src={Images.cards.cardDetailTimer} alt="timer-image" />
                                    </div >
                                    <div className='card-det-count'>{formatDate(cardDetailData.createdAt) || '23 Nov 2024, 12:00 pm'}</div>
                                </div>
                            </div>
                            <div className='card-like-comment'>
                                <div className='comment-img'>
                                    <img src={Images.cards.comment} alt="comment image" />
                                </div>
                                <div className='comment-txt'>23</div>
                                <div className='comment-img'>
                                    <img src={Images.cards.star} alt="comment image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (page === 'active-bets') &&
                    <div className='your-bet-container'>
                        <p>Your Bet</p>
                        <div className='betting-details'>
                            <p><span>Bet On</span> : <div className='selected-option'>Mike Tyson Win</div> </p>
                            <p><span>Bet On</span> : 23/09/2024 </p>
                            <p><span>Chances </span> : 70%</p>
                        </div>
                        <div className='betting-details'>
                            <p><span>Bet Amount</span> : ₱12,500 </p>
                            <p><span>Potential Payout</span> : ₱12,500 </p>
                            <p><span>Status </span> : <p className='bet-status mb-0'>Ongoing</p></p>
                        </div>
                    </div>
                }
                <div className='det-table-head-main'>
                    <div className='row det-table-head'>
                        <div className='col-md-4'>Outcome</div>
                        <div className='col-md-2'>Bets</div>
                        <div className='col-md-2'>Chances</div>
                        <div className='col-md-4'>Action</div>
                    </div>

                    {
                        cardDetailData?.options && Array.isArray(cardDetailData.options) && cardDetailData.options.map((option) => {

                            return (
                                <div key={option._Id} className='row det-table-data'>
                                    <div className='col-md-4 det-img-div-main'>

                                        <div className='det-img-div'>
                                            <img src={option.image} alt="image" />
                                        </div>
                                        <div className='det-img-txt'>{option.title}</div>
                                    </div>
                                    <div className="col-md-2 det-bets det-img-div-main">0</div>
                                    <div className="col-md-2 det-graph det-img-div-main">
                                        <RadialBarChart
                                            key={option._id}
                                            currentOdds={option.currentOdds}
                                            initialOdds={option.initialOdds}
                                        />
                                    </div>
                                    <div className='col-md-4 det-img-div-main'>
                                        <div className='card-buttons w-100 det-card-btns '>
                                            <button
                                                className={`Yes ${(option._id === selectedBetDetail.optionID) && selectedBetDetail.isPositive ? 'selected' : ''}`}
                                                onClick={() => handleSelectedBetDetail({ optionID: option._id, isPositive: true, selectedOptionDetail: option })}
                                            >
                                                {capitalizeFirstLetter(option.positive_text)}
                                            </button>
                                            <button
                                                className={`No ${(option._id === selectedBetDetail.optionID) && selectedBetDetail.isNegative ? 'selected' : ''}`}
                                                onClick={() => handleSelectedBetDetail({ optionID: option._id, isNegative: true, selectedOptionDetail: option })}
                                            >
                                                {capitalizeFirstLetter(option.negative_text)}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>


                <div className='det-desc-main'>
                    <div className='desc'>Description</div>
                    <div className='para'>
                        {
                            isExpanded ? cardDetailData.description : `${getTruncatedText(cardDetailData.description, 0, 300)}... `
                        }
                        <span className='more' onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? 'less' : 'more...'}
                        </span>
                    </div>
                </div>

                <div>
                    <div className='det-comments'>Comments</div>


                    <div className='text-area-main'>
                        <textarea placeholder='Add a comments' className='det-text-area ' id="" name="" >

                        </textarea>
                        <div className='post-btn'>
                            <ButtonComponent
                                style={{
                                    background: 'rgba(210, 43, 43, 1)',
                                    color: 'rgba(255, 255, 255, 1)',
                                    borderRadius: '6px',
                                    fontSize: '10px',
                                    padding: '5px 20px'
                                }}
                                text={'Post'} />
                        </div>
                    </div>

                </div>
                <div>
                    {commentsData.slice(0, commentsToShow).map((comment, index) => (
                        <div className='comments-data-main' key={index}>
                            <div className='comments-data'>
                                <div className='under-comments-data'>
                                    <div className='det-img-div'>
                                        <img src={Images.cards.detImgOne} alt="image" />
                                    </div>
                                    <div className='com-txt'>{comment.username}</div>
                                </div>
                                <div className='com-date'>{comment.date}</div>
                            </div>
                            <div className="com-para">
                                {comment.comment}
                            </div>
                            <div className='com-para-foter'>
                                <div className='com'>
                                    <img src={Images.cards.comment} alt="" />
                                    <span>{comment.commentCount}</span>
                                </div>
                                <div className='like'>
                                    <img src={Images.cards.like} alt="" />
                                    <span>{comment.likeCount}</span>
                                </div>
                                <span className='reply-span'>Reply</span>
                            </div>
                        </div>
                    ))}

                    {!allCommentsShown && (
                        <div className="load-more" onClick={handleLoadMore} >
                            Load More
                        </div>
                    )}

                </div>
            </div>
            <div className='col-md-4 pro-det-home'>
                <div className='card-detail-profile'>
                    <div className="pro-head">
                        <div className='pro-img-main'>

                            <div className='det-img-div'>
                                {/* <img src={Images.cards.detImgOne} alt="image" /> */}
                                <img src={selectedBetDetail?.selectedOptionDetail?.image} alt="image" />
                            </div>
                            <div className='pro-det-img-txt'>{selectedBetDetail?.selectedOptionDetail?.title}</div>
                        </div>

                        <div className={selectedBetDetail.isPositive ? 'yes' : '' || selectedBetDetail.isNegative ? 'no' : ''} >
                            <span>•</span>
                            <span>
                                {
                                    (selectedBetDetail.isPositive ? 'Yes' : '' || selectedBetDetail.isNegative ? 'No' : '')
                                }
                            </span>
                        </div>
                    </div>
                    <div className="outcome-main">
                        <div className='outcome-txt'>Outcome</div>

                        <div className='card-buttons w-100 det-card-btns '>
                            <button
                                className={`Yes ${selectedBetDetail.isPositive ? 'selected' : ''}`}
                                onClick={() => handleSelectedBetDetail({ ...selectedBetDetail, isPositive: true, isNegative: false })}
                            >
                                {/* Yes */}
                                {capitalizeFirstLetter(selectedBetDetail?.selectedOptionDetail?.positive_text)}
                            </button>
                            <button
                                className={`No  ${selectedBetDetail.isNegative ? 'selected' : ''}`}
                                onClick={() => handleSelectedBetDetail({ ...selectedBetDetail, isNegative: true, isPositive: false })}
                                >
                                {capitalizeFirstLetter(selectedBetDetail?.selectedOptionDetail?.negative_text)}
                                {/* No */}
                            </button>
                        </div>

                    </div>
                    <div className="pro-amount">
                        <div className='amount'>Amount</div>
                        <div className="balance">Balance : <span>0.0</span></div>
                    </div>
                    <div className='pro-bet-amount'>
                        <input
                            onChange={(e) => handleSelectedBetDetail({ ...selectedBetDetail, betAmount: e.target.value })}
                            value={selectedBetDetail.betAmount}
                            type="number" placeholder='Enter Betting Amount'
                        />
                    </div>
                    <div className="pro-price">
                        <div className='under-pro-price'>
                            <div className='pri-txt'>Avg Price</div>
                            <div className="pri-amt">0</div>
                        </div>
                        <div className='under-pro-price'>
                            <div className='pri-txt'>Winnings from Yes Bet</div>
                            <div className="pri-amt">54</div>
                        </div>
                        <div className='under-pro-price'>
                            <div className='pri-txt'>Profit</div>
                            <div className="pri-amt">0</div>
                        </div>
                    </div>

                    <div className='pro-confirm'>
                        <button disabled={isLoading} onClick={clickPlaceBet}>
                            <span>Confirm Bet on Yes</span>
                            <span className='px-2'>
                                <LoaderComponent isLoading={isLoading} />
                            </span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}
