import React, { createContext, useContext, useState } from 'react'



export const BetContext = createContext();

export const UseContext = ({ children }) => {
    const [isMobile, setIsMobile] = useState('');
    const [isAuthenticatedWith, setIsAuthenticatedWith] = useState({
        normal: false,
        social: false
    })
    const [profile, setProfile] = useState();
    return (
        <BetContext.Provider value={{
            isMobile, setIsMobile,
            isAuthenticatedWith, setIsAuthenticatedWith,
            profile, setProfile
        }}>
            {children}
        </BetContext.Provider>
    )
}
