// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-bg-color: rgba(255, 255, 255, 1);
  --gray-light: rgba(169, 169, 169, 1);
  --gray-dark: rgba(102, 102, 102, 1);
  --red-orange: rgba(210, 43, 43, 1);
  --clean-white: rgba(255, 255, 255, 1);
  --lucky-red: rgba(210, 43, 43, 1);
  --dark-black: rgba(51, 51, 51, 1);
  --font-10px: 10px;
  --font-12px: 12px;
  --font-14px: 14px;
  --font-16px: 16px;
  --font-18px: 18px;
  --font-20px: 20px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row{
  --bs-gutter-x: unset;
}


.content-wrapper {
  overflow: hidden; 
}

.content-wrapper::-webkit-scrollbar {
  display: none; 
}

html {
  -ms-overflow-style: none;  
  scrollbar-width: none;     
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,oCAAoC;EACpC,mCAAmC;EACnC,kCAAkC;EAClC,qCAAqC;EACrC,iCAAiC;EACjC,iCAAiC;EACjC,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,oBAAoB;AACtB;;;AAGA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB","sourcesContent":[":root {\n  --main-bg-color: rgba(255, 255, 255, 1);\n  --gray-light: rgba(169, 169, 169, 1);\n  --gray-dark: rgba(102, 102, 102, 1);\n  --red-orange: rgba(210, 43, 43, 1);\n  --clean-white: rgba(255, 255, 255, 1);\n  --lucky-red: rgba(210, 43, 43, 1);\n  --dark-black: rgba(51, 51, 51, 1);\n  --font-10px: 10px;\n  --font-12px: 12px;\n  --font-14px: 14px;\n  --font-16px: 16px;\n  --font-18px: 18px;\n  --font-20px: 20px;\n}\n.App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 10s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.row{\n  --bs-gutter-x: unset;\n}\n\n\n.content-wrapper {\n  overflow: hidden; \n}\n\n.content-wrapper::-webkit-scrollbar {\n  display: none; \n}\n\nhtml {\n  -ms-overflow-style: none;  \n  scrollbar-width: none;     \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
