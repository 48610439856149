import React, { useState } from "react";
import "./Pagination.css";
import { Images } from "../Images/Images";

const Pagination = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 59;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        console.log("Current page is:", pageNumber);
    };

    return (
        <div className="pagination-icon mt-3">
            {/* <h2>Current Page: {currentPage}</h2> */}
            <PaginatedComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default Pagination;



export const PaginatedComponent = ({ currentPage, totalPages, onPageChange }) => {
    const getPaginationGroup = () => {
        let startPage = Math.max(1, currentPage - 2);  // Start from 2 pages before current
        let endPage = Math.min(totalPages, currentPage + 2);  // End 2 pages after current

        // Ensure we always show 5 buttons, if possible
        if (currentPage <= 3) {
            endPage = Math.min(5, totalPages);
        } else if (currentPage + 2 >= totalPages) {
            startPage = Math.max(totalPages - 4, 1);
        }

        let paginationArray = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationArray.push(i);
        }

        return paginationArray;
    };

    const handlePageChange = (pageNumber) => {
        if (typeof pageNumber === "number" && pageNumber > 0 && pageNumber <= totalPages) {
            onPageChange(pageNumber);
        }
    };

    return (
        <div className="pagination">
            {/* Previous Page Button */}
            <button
                className="pagination-btn"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
            >
                <div className="pagination-icon">
                    <img src={Images.pagination.previousPage} alt="previous-page" />
                </div>
            </button>

            {/* Back Button */}
            <button
                className="pagination-btn"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <div className="pagination-icon">
                    <img src={Images.pagination.prev} alt="previous" />
                </div>
            </button>

            {/* Page Numbers */}
            {getPaginationGroup().map((pageNumber, index) => (
                <button
                    key={index}
                    className={`pagination-btn ${currentPage === pageNumber ? "active" : ""}`}
                    onClick={() => handlePageChange(pageNumber)}
                >
                    {pageNumber}
                </button>
            ))}

            {/* Forward Button */}
            <button
                className="pagination-btn"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                <div className="pagination-icon">
                    <img src={Images.pagination.next} alt="next" />
                </div>
            </button>

            {/* Next Page Button */}
            <button
                className="pagination-btn"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
            >
                <div className="pagination-icon">
                    <img src={Images.pagination.nextPage} alt="next-page" />
                </div>
            </button>
        </div>
    );
};


