import React, { useContext, useState } from 'react'
import './Sidebar.css'
import { Images } from '../Images/Images';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ButtonComponent, getUrlDetails } from '../../Utils/Helper/HelperFunction';
import { BetContext } from '../Context/UseContext';


export const Sidebar = ({ isCollapsed, setIsCollapsed, setShowForm }) => {
    const { isMobile, setIsMobile } = useContext(BetContext);
    const { isAuthenticatedWith } = useContext(BetContext);
    const location = useLocation();
    const { pageName, category: urlCategory } = getUrlDetails(location.pathname);
    const [activeTab, setActiveTab] = useState(pageName);



    const SIDEBAR_ITEMS = [
        { id: "home", title: "Home", icon: { black: Images.sidebar.home.black, red: Images.sidebar.home.red } },
        { id: "active-bets", title: "Active Bets", icon: { black: Images.sidebar.activeBets.black, red: Images.sidebar.activeBets }.red },
        { id: "sports", title: "Sports", icon: { black: Images.sidebar.sports.black, red: Images.sidebar.sports.red } },
        { id: "politics", title: "Politics", icon: { black: Images.sidebar.politics.black, red: Images.sidebar.politics.red } },
        { id: "entertainment", title: "Entertainment", icon: { black: Images.sidebar.entertainment.black, red: Images.sidebar.entertainment.red } },
        { id: "weather", title: "Weather", icon: { black: Images.sidebar.weather.black, red: Images.sidebar.weather.red } },
    ];


    function IconComponent({ onClick, icon }) {
        return <img onClick={onClick} src={icon} alt='icon' />
    }

    function SidebarItem({ item, activeTab, setActiveTab, isSidebarCollapsed }) {

        return (
            <Link
                className={`sidebar-item ${activeTab === item.id ? 'sidebar-item__active' : ''}`}
                onClick={() => setActiveTab(item.id)}
                to={`${item.id}/all`}
            >

                {/* Active background when the tab is active */}
                {activeTab === item.id && (<div className="sidebar-item__active-bg" />)}

                {/* Icon */}
                <span className="sidebar-item__icon">
                    <IconComponent icon={activeTab === item.id ? item.icon.red : item.icon.black} />
                </span>

                {/* Title with conditional rendering based on collapsed state */}
                <span className="sidebar-item__title"
                    style={{
                        visibility: isSidebarCollapsed ? 'hidden' : 'visible', // Hide when collapsed
                        opacity: isSidebarCollapsed ? 0 : 1, // Smooth transition for opacity
                        transition: 'opacity 0.3s ease, visibility 0.3s ease', // Smooth transitions
                    }}
                >
                    {item.title}
                </span>
            </Link>
        );
    }



    return (
        <div className={`sidebar ${isMobile}`}
            style={{
                width: isCollapsed ? `${(window.screen.width < 768) ? '0' : '90px'}` : '225px',
                padding: isCollapsed ? `${(window.screen.width < 768) ? '0' : ''}` : '',
                overflow: isCollapsed ? `${(window.screen.width < 768) ? 'hidden' : ''}` : '',
            }} >
            {/* <div className='sidebar-logo'>
                <img className='logo' src={Images.logoWithText} alt="logo" />
                </div> */}
            <div className={`sidebar-logo-main ${isCollapsed ? 'm-y-1' : 'm-y-3'}`} style={isCollapsed ?
                {
                    justifyContent: 'center',
                    marginTop: '1.5rem'
                }
                : {}
            } >
                <div style={{ margin: isCollapsed ? '1rem 0' : '' }}>
                    <img className='logo' src={Images.sidebar.openLogo} alt="logo" />
                </div>
                <button className="sidebar__collapse-button" onClick={() => setIsCollapsed(!isCollapsed)} >
                    {isCollapsed ? <IconComponent icon={Images.sidebar.hamburgerClose} /> : <IconComponent onClick={() => setIsMobile('mobile-sidebar')} icon={Images.sidebar.hamburgerOpen} />}
                </button>
            </div>

            {SIDEBAR_ITEMS.map((item) => (
                <SidebarItem
                    isSidebarCollapsed={isCollapsed}
                    key={item.id}
                    item={item}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            ))}

            {
                !(isAuthenticatedWith.social || isAuthenticatedWith.normal) &&
                <div className='mobile-btns-main'>
                    <div className='mobile-btns'>
                        <ButtonComponent
                            onClick={() => setShowForm({ login: true })}
                            style={{
                                color: 'rgba(210, 43, 43, 1)',
                                background: 'none',
                                width: '100%'
                            }}
                            text={'Login'} />
                        <ButtonComponent
                            onClick={() => setShowForm({ signup: true })}
                            style={{
                                background: 'rgba(210, 43, 43, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                width: '100%'
                            }}
                            text={'Signup'} />
                    </div>
                </div>
            }
        </div >
    );

}


