import React, { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../Components/Sidebar/Sidebar';
import { Navbar } from '../Components/Navbar/Navbar';
import LoginPopup from '../Components/Login/LoginPopup';
import { Footer } from '../Components/Footer/Footer';
import './ProtectedRoute.css'; // Import the CSS
import { isMobileWidth } from './Helper/HelperFunction';
import { BetContext } from '../Components/Context/UseContext';

export default function ProtectedRoute({  }) {

  const [isCollapsed, setIsCollapsed] = useState(isMobileWidth);

  const [showForm, setShowForm] = useState({
    login: false,
    signup: false,
    forgotPassword: false,
  });


  return (
    <div>
      {/* Fixed Sidebar with dynamic class based on collapsed state */}
      <div className={!isMobileWidth ? (`fixed-sidebar ${isCollapsed ? 'collapsed' : ''}`) : (`mobile fixed-sidebar ${isCollapsed ? 'collapsed' : ''}`)}>
        <Sidebar
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          setShowForm={setShowForm}
        />
      </div>

      {/* Fixed Navbar with dynamic class based on collapsed state */}
      <div className={!isMobileWidth ? (`fixed-navbar ${isCollapsed ? 'collapsed' : ''}`) : (`mobile fixed-navbar ${isCollapsed ? 'collapsed' : ''}`)}>
        <Navbar
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          showForm={showForm}
          setShowForm={setShowForm}
        />
      </div>

      {/* Main Content Area with dynamic class based on collapsed state */}
      <div className={!isMobileWidth ? (`content-wrapper ${isCollapsed ? 'collapsed' : ''}`) : (`mobile content-wrapper ${isCollapsed ? 'collapsed' : ''}`)}>
        <div className='outlet'>
          <Outlet />
        </div>
        <Footer />
      </div>

      {/* Login Popup */}
      <LoginPopup showForm={showForm} />
    </div>
  );
}



// import React, { useState } from 'react'
// import { Navigate, Outlet } from 'react-router-dom'
// import { Sidebar } from '../Components/Sidebar/Sidebar';
// import { Navbar } from '../Components/Navbar/Navbar';
// import LoginPopup from '../Components/Login/LoginPopup';
// import { Footer } from '../Components/Footer/Footer';
// export default function ProtectedRoute({ onLogin, onLogout, isAuthenticated }) {


//   const [isCollapsed, setIsCollapsed] = useState(false);
//   const [showForm, setShowForm] = useState({
//     login: false,
//     signup: false,
//     forgotPassword: false
//   })
//   const handleLogin = () => onLogin();
//   const handleLogout = () => onLogout();

//   // if (!isAuthenticated) {
//   //   return <Navigate to="/login" />;
//   // }

//   console.log(isCollapsed);


//   return (
//     <>

//       <div className='col-md-12'>
//         <div className='d-flex w-100'>
//           {
//             <LoginPopup OnLogin={handleLogin} showForm={showForm} />
//           }
//           <Sidebar
//             isCollapsed={isCollapsed}
//             setIsCollapsed={setIsCollapsed}
//             setShowForm={setShowForm}
//           />

//           <div className='w-100'>
//             <Navbar
//               isCollapsed={isCollapsed}
//               setIsCollapsed={setIsCollapsed}
//               showForm={showForm}
//               OnLogout={handleLogout}
//               isAuthenticated={isAuthenticated}
//               setShowForm={setShowForm}
//             />
//             <div className='px-3'>
//               <Outlet />
//             </div>
//             <Footer />
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }
