import axios from "axios";
import { useSessionStorageData } from "../Utils/Helper/HelperFunction";
const GetRequest = async (service, path, params) => {

  let sessionStorageData = useSessionStorageData();

  let token = sessionStorageData?.googleAccessToken || sessionStorageData?.apiUserData?.tokens?.access?.token;


  const addBearerToken = (headers, token) => {
    let removeCoutes = String(token).replace(/"/g, '');
    if (!token.startsWith('Bearer ')) {
      token = `Bearer ${removeCoutes}`;
    }

    headers.Authorization = token;

    return headers;
  };

  try {
    let header = {
      "content-type": "application/json",
    };

    if (token) {
      header = addBearerToken(header, token);
    }

    const response = await axios.get(`${service}/${path}/`, {
      headers: header,
      params: params
    });
    return response;
  } catch (error) {
    throw error
    // toast.error(error.message);
  }
};

export default GetRequest;
