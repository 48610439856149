import menWithPhone from '../../Assets/man-with-phone.png'
import logo from '../../Assets/Logo.png'
import eyeIcon from '../../Assets/eye-icon.png'
import cutIcon from '../../Assets/cut-icon.png'
import redCutIcon from '../../Assets/red-cut-icon.png'
import google from '../../Assets/Google.png'
import logoWithText from '../../Assets/logo-with-text.png'
import hamburgerOpen from '../../Assets/Sidebar/hamburger-open.png'
import hamburgerClose from '../../Assets/Sidebar/hamburger-close.png'
import openLogo from '../../Assets/sidebar-open-logo.png'
import homeBlack from '../../Assets/Sidebar/black/home.png'
import activeBetsBlack from '../../Assets/Sidebar/black/active-bets.png'
import sportsBlack from '../../Assets/Sidebar/black/sports.png'
import politicsBlack from '../../Assets/Sidebar/black/politics.png'
import entertainmentBlack from '../../Assets/Sidebar/black/entertainment.png'
import weatherBlack from '../../Assets/Sidebar/black/weather.png'
import homeRed from '../../Assets/Sidebar/red/home.png'
import activeBetsRed from '../../Assets/Sidebar/red/active-bets.png'
import sportsRed from '../../Assets/Sidebar/red/sports.png'
import politicsRed from '../../Assets/Sidebar/red/politics.png'
import entertainmentRed from '../../Assets/Sidebar/red/entertainment.png'
import weatherRed from '../../Assets/Sidebar/red/weather.png'
import searchIcon from '../../Assets/Navbar/search-icon.png'
import notificationIcon from '../../Assets/Navbar/notification-icon.png'
import dummyProfileImg from '../../Assets/Navbar/dummy-profile-img.jpg'
import starIcon from '../../Assets/Navbar/star.png'
import profileIcon from '../../Assets/Navbar/profile-icon.png'
import settingIcon from '../../Assets/Navbar/setting-icon.png'
import logoutIcon from '../../Assets/Navbar/logout-icon.png'
import walletIcon from '../../Assets/Navbar/wallet_icon.png'
import bannerOne from '../../Assets/Crousel/banner-1.png'
import bannerTwo from '../../Assets/Crousel/banner-2.png'
import imageOne from '../../Assets/Cards/image (1).png'
import parking from '../../Assets/Cards/parking.png'
import comment from '../../Assets/Cards/comment.png'
import star from '../../Assets/Cards/star.png'
import prev from '../../Assets/Pagination/prev.png'
import next from '../../Assets/Pagination/next.png'
import previousPage from '../../Assets/Pagination/previous-page.png'
import nextPage from '../../Assets/Pagination/next-page.png'
import sortIcon from '../../Assets/sort-icon.png'
import selectedIcon from '../../Assets/selected-icon.png'
import unSelectedIcon from '../../Assets/unselected-icon.png'
import cardDetailBack from '../../Assets/Cards/card-back.png'
import cardDetailTimer from '../../Assets/Cards/card-detail-timer.png'
import detImgOne from '../../Assets/Cards/det-img-one.png'
import detImgTwo from '../../Assets/Cards/det-img-two.png'
import like from '../../Assets/Cards/like.png'
import noEvents from '../../Assets/Cards/no-events.png'
import profileImg from '../../Assets/Profile/profile-img.png'
import Time from '../../Assets/time.png'
import editIcon from '../../Assets/Profile/edit-icon.png'
import totalBets from '../../Assets/Profile/total-bet.png'
import totalWon from '../../Assets/Profile/total-won.png'
import yelloDot from '../../Assets/Profile/yello-dot.png'
import greenDot from '../../Assets/Profile/green-dot.png'
import redProfileIcon from '../../Assets/Profile/red-profile.png'
import redNotificationIcon from '../../Assets/Profile/red-notification.png'
import plusIcon from '../../Assets/MyWallet/plus-icon.png'
import withdrawMoney from '../../Assets/MyWallet/withdraw-money.png'
import redeemCoupon from '../../Assets/MyWallet/redeem-coupon.png'
import copyIcon from '../../Assets/MyWallet/copyIcon.png'
import usdtIcon from '../../Assets/MyWallet/usdtIcon.png'
import refreshIcon from '../../Assets/MyWallet/refresh-icon.png'
import filterIcon from '../../Assets/MyWallet/filter-icon.png'
import dateIcon from '../../Assets/MyWallet/date-icon.png'
import arrowLeft from '../../Assets/MyWallet/arrow-letf.png'
import arrowRight from '../../Assets/MyWallet/arrow-right.png'





export const Images = {
    menWithPhone: menWithPhone,
    logo: logo,
    logoWithText: logoWithText,
    eyeIcon: eyeIcon,
    cutIcon: cutIcon,
    redCutIcon: redCutIcon,
    google: google,
    sortIcon: sortIcon,
    selectedIcon: selectedIcon,
    unSelectedIcon: unSelectedIcon,
    sidebar: {
        hamburgerOpen: hamburgerOpen,
        hamburgerClose: hamburgerClose,
        openLogo: openLogo,
        home: {black: homeBlack, red: homeRed},
        activeBets: {black: activeBetsBlack, red: activeBetsRed},
        sports: {black: sportsBlack, red: sportsRed},
        politics: {black: politicsBlack, red: politicsRed},
        entertainment: {black: entertainmentBlack, red: entertainmentRed},
        weather: {black: weatherBlack, red: weatherRed},
    },
    navbar: {
        searchIcon: searchIcon,
        notificationIcon: notificationIcon,
        dummyProfileImg: dummyProfileImg,
        profileIcon: profileIcon,
        settingIcon: settingIcon,
        logoutIcon: logoutIcon,
        starIcon: starIcon,
        walletIcon: walletIcon,
    },
    crousel: {
        bannerOne: bannerOne,
        bannerTwo: bannerTwo
    },
    cards: {
        imageOne: imageOne,
        parking: parking,
        comment: comment,
        star: star,
        like: like,
        cardDetailBack: cardDetailBack,
        cardDetailTimer: cardDetailTimer,
        detImgOne:detImgOne,
        detImgTwo:detImgTwo,
        time: Time,
        noEvents: noEvents
    },
    pagination: {
        prev: prev,
        next: next,
        previousPage: previousPage,
        nextPage: nextPage
    },
    profile: {
        profileImg: profileImg,
        editIcon: editIcon,
        totalBets: totalBets,
        totalWon:totalWon,
        yelloDot: yelloDot,
        greenDot: greenDot,
        redProfileIcon: redProfileIcon,
        redNotificationIcon: redNotificationIcon
    },
    myWallet: {
        plusIcon: plusIcon,
        withdrawMoney: withdrawMoney,
        redeemCoupon: redeemCoupon,
        copyIcon: copyIcon,
        usdtIcon: usdtIcon,
        refreshIcon: refreshIcon,
        filterIcon: filterIcon,
        dateIcon: dateIcon,
        arrowLeft: arrowLeft,
        arrowRight: arrowRight,
    }
}