import React, { useEffect, useRef, useState } from 'react';
import './DataTable.css';
import { PaginatedComponent } from '../Pagination/Pagination';
import { Images } from '../Images/Images';
import { ButtonComponent } from '../../Utils/Helper/HelperFunction';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';



export const DataTable = ({ headers, data, itemsPerPage = 10 }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;

    const currentData = data.slice(startIdx, endIdx);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const filterButtons = {
        DEPOSIT: 'Deposit',
        WITHDRAWAL: 'Withdrawal',
        BET_PLACED: 'Bet Placed',
    }


    return (
        <div className="data-table-main">
            <div className="">
                <div className='tab-header-main'>

                    <div className='tab-header'>
                        <div className='title'>Transaction</div>
                        <div className='desc'>Manage and track all transaction</div>
                    </div>

                    <div className='tab-head-sec-group'>
                        <div className="search-form h-100">
                            <button type="submit" className="search-submit" disabled><img src={Images.navbar.searchIcon} alt="Search Icon" /></button>
                            <input type="text" name="search" className="search-input" placeholder="search events..." autoFocus />
                        </div>
                        <div>
                            < FilterComponent
                                buttons={Object.entries(filterButtons)}
                                onClick={(e) => console.log(e)}
                            />
                        </div>
                        <div>
                            <ButtonComponent
                                onClick={() => { }}
                                style={{
                                    color: 'rgba(210, 43, 43, 1)',
                                    background: 'rgba(210, 43, 43, 1)',
                                    border: 'none',
                                    borderRadius: '6px',
                                    padding: '10px 20px'
                                }}
                                text={
                                    <div className='add-money-div'>
                                        <span>
                                            <img src={Images.myWallet.refreshIcon} alt="Plus Icon" />
                                        </span>
                                        <span>Refresh</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
                <table className="mt-1" id="employee-table">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody id="table-body">
                        {currentData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {Object.values(row).map((value, cellIndex) => {
                                    console.log(value)

                                    return (

                                        <td className='' key={cellIndex}>
                                            {typeof value === 'object' && value?.icon ? (
                                                <>
                                                    {value.icon}
                                                    {value.amount}
                                                </>
                                            ) :

                                                (
                                                    (value == 'Pending' || value == 'Completed') ?
                                                        <div className={`${value} dot-div`}>
                                                            <span className='img-sp'>
                                                                <img src={value === 'Pending' ? Images.profile.yelloDot : Images.profile.greenDot} alt="dot-img" />
                                                            </span>
                                                            <span>
                                                                {`${value}`}
                                                            </span>

                                                        </div>
                                                        : value
                                                )}
                                        </td>
                                    )
                                }
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <PaginatedComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
            <DateRangePicker />
        </div>
    );
};



const FilterComponent = ({ buttons = [], onClick, activeButton = 'Deposit' }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isCalenderOpen, setIsCalenderOpen] = useState(false)
    const dropdownRef = useRef(null);

    const handleClose = () => {
        setDropdownOpen(false);
    };

    const handleToggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            event.stopPropagation();
        }
    };

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => document.removeEventListener('click', handleClickOutside);
    }, [isDropdownOpen]);

    return (
        <div className="sorting-dropdown" ref={dropdownRef}>
            {/* {isCalenderOpen && <Calendar />} */}
            <button
                className='filter-btn'
                type="button"
                onClick={handleToggleDropdown}
            >
                <div className='sport-sort-icon'>
                    <img src={Images.myWallet.filterIcon} alt="sort-icon" />
                </div>
                <div className='filter-txt'>Filters</div>
            </button>

            {isDropdownOpen && (
                <ul className=" filter-menu p-4">
                    <>
                        <div className='sort-head'>
                            <span className='filter-by'>Transaction Type</span>
                            <span className='filter-cut-icon' onClick={handleClose}>
                                <img src={Images.redCutIcon} alt="redcut-icon" />
                            </span>
                        </div>
                        <div className='sort-body'>
                            {buttons.map(([key, value], index) => {
                                let _activeButton = activeButton === value;
                                return (
                                    <li
                                        onClick={() => onClick({ key, value })}
                                        className='sort-item'
                                        key={index}
                                    >
                                        <div className='sort-item-icon'>
                                            <img
                                                src={_activeButton ? Images.selectedIcon : Images.unSelectedIcon}
                                                alt=""
                                            />
                                        </div>
                                        <span className='sort-item-txt'>{value}</span>
                                    </li>
                                );
                            })}
                        </div>
                        <div className='mt-2'>
                            <div className='filter-by'>History Range</div>
                            {/* <div className='date-main'>
                                <div className='txt'>Select Date Range</div>
                                <div className='img-div'
                                    onClick={() => {
                                        setIsCalenderOpen(!isCalenderOpen)
                                    }}
                                >
                                    <img src={Images.myWallet.dateIcon} alt="date icon" />
                                </div>
                            </div> */}
                            <DateRangePicker placeholder="Select Date Range" />
                        </div>

                        <div className='filter-fot-btns'>
                            <ButtonComponent
                                onClick={() => { }}
                                style={{
                                    color: 'rgba(210, 43, 43, 1)',
                                    background: 'rgba(255, 255, 255, 1)',
                                    borderRadius: '6px',
                                    padding: '5px 20px',
                                    margin: '0',
                                    fontSize: '10px',
                                }}
                                text={'Reset'}
                            />
                            <ButtonComponent
                                onClick={() => { }}
                                style={{
                                    color: 'rgba(255, 255, 255, 1)',
                                    background: 'rgba(210, 43, 43, 1)',
                                    border: 'none',
                                    borderRadius: '6px',
                                    padding: '5px 20px',
                                    margin: '0',
                                    fontSize: '10px',
                                }}
                                text={'Apply Filter'}
                            />
                        </div>
                    </>
                </ul>
            )}
        </div>
    );
};


// const Calendar = () => {
//     const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
//     const years = Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i); // Shows 10 years before and after current year

//     const [date, setDate] = useState(new Date());
//     const [currentMonth, setCurrentMonth] = useState(date.getMonth());
//     const [currentYear, setCurrentYear] = useState(date.getFullYear());
//     const [days, setDays] = useState([]);
//     const [activeDay, setActiveDay] = useState(date.getDate());
//     const [view, setView] = useState('day'); // View state: 'year', 'month', 'day'

//     useEffect(() => {
//         renderDaysOfMonth();
//     }, [currentMonth, currentYear]);

//     const handleMonthChange = (direction) => {
//         let newMonth = currentMonth + direction;
//         if (newMonth < 0) newMonth = 11; // December
//         if (newMonth > 11) newMonth = 0; // January
//         setCurrentMonth(newMonth);
//         setDate(new Date(currentYear, newMonth));
//     };

//     const getDaysOfMonth = () => {
//         return new Date(currentYear, currentMonth + 1, 0).getDate();
//     };

//     const renderDaysOfMonth = () => {
//         const lastDay = getDaysOfMonth();
//         let daysArray = [];
//         for (let day = 1; day <= lastDay; day++) {
//             daysArray.push(day);
//         }
//         setDays(daysArray);
//     };

//     const handleYearClick = () => {
//         setView('year');
//     };

//     const handleYearSelect = (year) => {
//         setCurrentYear(year);
//         setView('month');
//     };

//     const handleMonthSelect = (monthIndex) => {
//         setCurrentMonth(monthIndex);
//         setView('day');
//     };

//     return (
//         <div>
//             <div className="cldr-container">
//                 <div className='cldr-head-main'>
//                     <div className='filter-fot-btns'>
//                         <button>History Range</button>
//                         <button>Reset</button>
//                         <button>Apply Filter</button>
//                     </div>
//                 </div>
//                 <div className='cld-body'>
//                     {/* Conditionally render based on the current view */}
//                     {view === 'year' && (
//                         <div className="cldr-years-box cldr-content">
//                             <header className='cldr-header'>
//                                 <span>Select Year</span>
//                             </header>
//                             <main className="cldr-main-years">
//                                 {years.map((year) => (
//                                     <div
//                                         key={year}
//                                         className={`add-hover ${currentYear === year ? 'active' : ''}`}
//                                         onClick={() => handleYearSelect(year)}
//                                     >
//                                         {year}
//                                     </div>
//                                 ))}
//                             </main>
//                         </div>
//                     )}

//                     {view === 'month' && (
//                         <div className="cldr-months-box cldr-content">
//                             <header className='cldr-header'>
//                                 <span onClick={handleYearClick}>{currentYear}</span>
//                             </header>
//                             <main className="cldr-main-months">
//                                 {months.map((month, index) => (
//                                     <div
//                                         key={index}
//                                         className={`add-hover ${currentMonth === index ? 'active' : ''}`}
//                                         onClick={() => handleMonthSelect(index)}
//                                     >
//                                         {month}
//                                     </div>
//                                 ))}
//                             </main>
//                         </div>
//                     )}

//                     {view === 'day' && (
//                         <div className="cldr-days-month-box cldr-content">
//                             <header className='cldr-header'>
//                                 <span onClick={() => setView('month')}>{months[currentMonth]} {currentYear}</span>
//                             </header>
//                             <main className="cldr-main-days">
//                                 {days.map((day) => (
//                                     <div
//                                         key={day}
//                                         className={`add-hover ${activeDay === day ? 'active' : ''}`}
//                                         onClick={() => setActiveDay(day)}
//                                     >
//                                         {day}
//                                     </div>
//                                 ))}
//                             </main>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div >
//     );
// };

// export default Calendar;



// const Calendar = ({ }) => {
//     const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
//     const [date, setDate] = useState(new Date());
//     const [currentMonth, setCurrentMonth] = useState(date.getMonth());
//     const [days, setDays] = useState([]);
//     const [activeDay, setActiveDay] = useState(date.getDate());

//     useEffect(() => {
//         renderDaysOfMonth();
//     }, [currentMonth]);

//     const handleMonthChange = (direction) => {
//         let newMonth = currentMonth + direction;
//         if (newMonth < 0) newMonth = 11; // December
//         if (newMonth > 11) newMonth = 0; // January
//         setCurrentMonth(newMonth);
//         setDate(new Date(date.getFullYear(), newMonth));
//     };

//     const getDaysOfMonth = () => {
//         return new Date(date.getFullYear(), currentMonth + 1, 0).getDate();
//     };

//     const renderDaysOfMonth = () => {
//         const lastDay = getDaysOfMonth();
//         let daysArray = [];
//         for (let day = 1; day <= lastDay; day++) {
//             daysArray.push(day);
//         }
//         setDays(daysArray);
//     };

//     return (
//         <div>
//             <div className="cldr-container">
//                 <div className='cldr-head-main'>
//                     <div className='filter-fot-btns'>
//                         <ButtonComponent
//                             onClick={() => { }}
//                             style={{
//                                 color: 'rgba(210, 43, 43, 1)',
//                                 background: 'rgba(255, 255, 255, 1)',
//                                 borderRadius: '6px',
//                                 padding: '5px 20px',
//                                 margin: '0',
//                                 fontSize: '10px',
//                             }}
//                             text={'History Range'}
//                         />
//                         <ButtonComponent
//                             onClick={() => { }}
//                             style={{
//                                 color: 'rgba(210, 43, 43, 1)',
//                                 background: 'rgba(255, 255, 255, 1)',
//                                 borderRadius: '6px',
//                                 padding: '5px 20px',
//                                 margin: '0',
//                                 fontSize: '10px',
//                             }}
//                             text={'Reset'}
//                         />
//                         <ButtonComponent
//                             onClick={() => { }}
//                             style={{
//                                 color: 'rgba(255, 255, 255, 1)',
//                                 background: 'rgba(210, 43, 43, 1)',
//                                 border: 'none',
//                                 borderRadius: '6px',
//                                 padding: '5px 20px',
//                                 margin: '0',
//                                 fontSize: '10px',
//                             }}
//                             text={'Apply Filter'}
//                         />
//                     </div>
//                 </div>
//                 <div className='cld-body'>


//                     {/* Months Box */}
//                     <div className="cldr-months-box cldr-content">
//                         <header className='cldr-header'>
//                             <img src={Images.myWallet.arrowLeft} alt="arrow-left" className="ph-bold ph-caret-left" onClick={() => handleMonthChange(-1)} />
//                             <span>{date.getFullYear()}</span>
//                             <img src={Images.myWallet.arrowRight} alt="arrow-right" className="ph-bold ph-caret-right" onClick={() => handleMonthChange(1)} />
//                         </header>
//                         <main className="cldr-main-months">
//                             {months.map((month, index) => (
//                                 <div key={index} className={`add-hover ${currentMonth === index ? 'active' : ''}`}>
//                                     {month}
//                                 </div>
//                             ))}
//                         </main>
//                     </div>

//                     {/* Days Box */}
//                     <div className="cldr-days-month-box cldr-content">
//                         <header className='cldr-header'>
//                             <img src={Images.myWallet.arrowLeft} alt="arrow-left" onClick={() => handleMonthChange(-1)} />
//                             <span>{`${date.toLocaleString('pt', { month: 'long' }).toUpperCase()} ${date.getFullYear()}`}</span>
//                             <img src={Images.myWallet.arrowRight} alt="arrow-right" className="ph-bold ph-caret-right" onClick={() => handleMonthChange(1)} />
//                         </header>
//                         <main className="cldr-main-days">
//                             {days.map((day) => (
//                                 <div
//                                     key={day}
//                                     className={`add-hover ${activeDay === day ? 'active' : ''}`}
//                                     onClick={() => setActiveDay(day)}
//                                 >
//                                     {day}
//                                 </div>
//                             ))}
//                         </main>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     );
// };

