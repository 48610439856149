
import React from 'react'
import './Cards.css'
import { Images } from '../Images/Images'
import RadialBarChart from '../RadialBarChart/RadialBarChart'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatPathName, formatAndLowerPath, getUrlDetails, formatDate } from '../../Utils/Helper/HelperFunction'
export const ActiveBetCards = ({ activeBet }) => {

    console.log('activeBet::-----', activeBet);
    
    const navigate = useNavigate();
    const location = useLocation();
    const { pageName, category } = getUrlDetails(location.pathname);


    return (
        <div className='col-md-6 fix-width'>
            <div className='card-main'>
                <div className='d-flex justify-centent-between w-100' style={{
                    justifyContent: 'space-between'
                }}>
                    <div className='card-main-one'>
                        <div className='image-div'>
                            <img src={activeBet.event_detail[0].image} alt="card-image" />
                        </div>
                        <div>{activeBet.event_detail[0].title}</div>
                    </div>
                    {/* <div onClick={() => navigate(`/${pageName}/${category}/event-detail/id-change-it-later`)} title='View' className='view-arrow mb-0'>{">>"}</div> */}
                    <div onClick={() => navigate(`/${pageName}/${category}/event-detail/${activeBet.event}`)} title='View' className='view-arrow mb-0'>{">>"}</div>
                </div>

                <div className='act-card-chance-bar-main'>
                    <div className='category-para'>{activeBet.event_detail[0].category}</div>
                    <div className='d-flex align-items-center gap-2'>
                        <p className='bet-on mb-0'>Bets On: </p>
                        <div className='chhosed-para'>{activeBet.option_detail[0].title}</div>
                    </div>
                    <div className="chance-bar">
                        <RadialBarChart
                         currentOdds={activeBet.option_detail[0].currentOdds}
                         initialOdds={activeBet.option_detail[0].initialOdds}
                         />
                        <div className='chances'>Chances</div>
                    </div>
                </div>

                <div className='d-flex bet-info-list justify-content-between align-items-center'>
                    <p className='mb-0'><span>Bet Amount </span> : ₱{activeBet.amount || '0'} </p>
                    <p className='mb-0'><span>Potential Payout </span> : ₱0 </p>
                    <p className='mb-0'><span>Status </span> : {activeBet.status} </p>
                </div>

                <div className='card-footer-main p-0'>
                    <div className='card-footer-one'>
                        <div>
                            <img src={Images.cards.parking} alt="parking image" />
                        </div >
                        <div className='bet-count'>474.84K  Bet</div>
                    </div>
                    <div className='card-footer-one'>
                        <div>
                            <img src={Images.cards.time} alt="time: " />
                        </div>
                        {/* <div className='bet-count'>23 Nov 2024, 12:00 pm</div> */}
                        <div className='bet-count'>{formatDate(activeBet.placed_at) || '23 Nov 2024, 12:00 pm'}</div>
                    </div>
                </div>

            </div>
        </div>
    )






    // return (
    //     <div className='col-md-6 fix-width'>
    //         <div className='card-main'>
    //             <div className='d-flex justify-centent-between'>
    //                 <div className='card-main-one'>
    //                     <div className='image-div'>
    //                         <img src={Images.cards.imageOne} alt="card-image" />
    //                     </div>
    //                     <div>Who Will win today argument on passing the bill in the parliament?</div>
    //                 </div>
    //                 <p onClick={() => navigate(`/${pageName}/${category}/event-detail/id-change-it-later`)} title='View' className='view-arrow mb-0'>{">>"}</p>
    //             </div>

    //             <div className='act-card-chance-bar-main'>
    //                 <div className='category-para'>Sports</div>
    //                 <div className='d-flex align-items-center gap-2'>
    //                     <p className='bet-on mb-0'>Bets On: </p>
    //                     <div className='chhosed-para'>Mike Tyson Win</div>
    //                 </div>
    //                 <div className="chance-bar">
    //                     <RadialBarChart />
    //                     <div className='chances'>Chances</div>
    //                 </div>
    //             </div>

    //             <div className='d-flex bet-info-list justify-content-between align-items-center'>
    //                 <p className='mb-0'><span>Bet Amount </span> : ₱12,500 </p>
    //                 <p className='mb-0'><span>Potential Payout </span> : ₱12,500 </p>
    //                 <p className='mb-0'><span>Status </span> : Pending </p>
    //             </div>

    //             <div className='card-footer-main p-0'>
    //                 <div className='card-footer-one'>
    //                     <div>
    //                         <img src={Images.cards.parking} alt="parking image" />
    //                     </div >
    //                     <div className='bet-count'>474.84K  Bet</div>
    //                 </div>
    //                 <div className='card-footer-one'>
    //                     <div>
    //                         <img src={Images.cards.time} alt="time: " />
    //                     </div>
    //                     <div className='bet-count'>23 Nov 2024, 12:00 pm</div>
    //                 </div>
    //             </div>

    //         </div>
    //     </div>
    // )
}
