import React, { useState } from 'react'
import './Favourite.css'
import { ButtonComponent, CategoryButtonsComponent, SortingDropdownComponent } from '../../Utils/Helper/HelperFunction';
import { Images } from '../../Components/Images/Images';
import { DummyCardsOne } from '../../Components/Cards/DummyCardsOne';
import Pagination from '../../Components/Pagination/Pagination';


export const Favourite = () => {
    const [category, setCategory] = useState({ key: 'ALL', value: 'All' });
    const [sort, setSort] = useState({ key: 'ALL', value: 'All' });
    const [searchTerm, setSearchTerm] = useState('');
    const buttons = {
        ALL: 'All',
        NEW: 'New',
        TOP: 'Top',
        TRENDING: 'Trending',
        ENDING_SOON: 'Ending Soon'
    }

    const handleCategoryChange = ({ key, value }) => {
        setCategory({ key, value });
    };
    const handleSortChange = ({ key, value }) => {
        setSort({ key, value });
    };
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        console.log('Searching for:', searchTerm);
    };

    let cardsLength = 0
    return (
        <div className='favourite-main'>
            <div className=' sports-btn-dropsdown'>
                <div className='sports-btn'>
                    <CategoryButtonsComponent onClick={(e) => handleCategoryChange(e)} buttons={Object.entries(buttons)} isActive={category.value} />
                </div>
                <div className='sports-grp-search-sort'>
                    <div style={{ paddingBottom: '10px' }}>
                        <div className=' fav-srh'>
                            <form className="sports-search-form">
                                <button type="submit" className="sport-search-submit" disabled>
                                    <img src={Images.navbar.searchIcon} alt="Search Icon" />
                                </button>
                                <input onChange={handleSearchChange} value={searchTerm} type="text" name="search" className="sport-search-input" placeholder="search events..." autoFocus />
                            </form>
                        </div>
                    </div>

                    <div className='sports-sort'>
                        <ButtonComponent
                            onClick={() => { }}
                            style={{
                                color: 'rgba(210, 43, 43, 1)',
                                background: 'none',
                                // fontSize: '14px',
                                margin: '0',
                                padding: '10px 15px',
                                width: '100%'
                            }}
                            text={'Remove All'} />
                    </div>
                </div>
            </div>
            {
                (cardsLength > 0) ?
                    <>
                        <div className='row cards-home g-3 justify-content-between favourite'>
                            <DummyCardsOne />
                            <DummyCardsOne />
                            <DummyCardsOne />
                            <DummyCardsOne />
                            <DummyCardsOne />
                            <DummyCardsOne />
                        </div>
                        <Pagination />
                    </>
                    :
                    <div className='no-event-main'>
                        <div className="no-event-img"></div>
                        <div className='no-event-under-main'>
                            <div className='head'>No Event Found!</div>
                            <div className='para'>We couldn’t find any jobs matching your search criteria. Try adjusting your filters or search terms.</div>
                            <div className='btn'>
                                <ButtonComponent
                                    onClick={() => { }}
                                    style={{
                                        color: 'rgba(255, 255, 255, 1)',
                                        background: 'rgba(210, 43, 43, 1)',
                                        // fontSize: '14px',
                                        margin: '0',
                                        padding: '10px 15px',
                                        width: '100%'
                                    }}
                                    text={'Remove All'} />
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}
