import React, { useContext } from 'react'
import './Navbar.css'
import { Images } from '../Images/Images'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonComponent, formatPathName, generateHamBurgerPath, getUrlDetails, useSessionStorageData } from '../../Utils/Helper/HelperFunction'
import Cookies from "js-cookie";
import { BetContext } from '../Context/UseContext'
import PostRequest from '../../services/PostRequest'
import { useAuth0 } from '@auth0/auth0-react'
import auth0Config from '../../Utils/auth0-config'


export const Navbar = ({ isCollapsed, setIsCollapsed, showForm, setShowForm }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticatedWith, setIsAuthenticatedWith } = useContext(BetContext);
  const { pageName, category, IsEventDetail } = getUrlDetails(location.pathname);
  const hamBurgerPath = generateHamBurgerPath(pageName, category, IsEventDetail);
  let sessionStorageData = useSessionStorageData();
  const { logout, user } = useAuth0();


  const handleLogout = (e) => {
    e.preventDefault();
    // try {
    //   PostRequest('logout')
    // } catch (error) {
    //   console.log(error);
    // }
    // return

    localStorage.clear();
    sessionStorage.clear();
    setIsAuthenticatedWith({ normal: false, social: false })

    Cookies.remove("nameEmailOrMobile");
    Cookies.remove("password");


    logout({ returnTo: auth0Config.redirectUri })
  }


  return (
    <>

      <div className='navbar'>
        <div className='breadcrumb'>
          <div>{formatPathName(pageName)}</div>
          <div>{hamBurgerPath}</div>
        </div>
        <button className="nav-open-icon">
          <IconComponent onClick={() => {
            setIsCollapsed(false)
          }} icon={Images.sidebar.hamburgerOpen} />
        </button>
        <div className='mobile-nav-logo'>
          <div >
            <img src={Images.logoWithText} alt="mobile-logo" />
          </div>
        </div>
        <header className="header" id="header">
          <nav >
            {/* <div className="search"> */}
            <form className="search-form">
              <button type="submit" className="search-submit" disabled><img src={Images.navbar.searchIcon} alt="Search Icon" /></button>
              <input type="text" name="search" className="search-input" placeholder="search events..." autoFocus />
            </form>
            {/* </div> */}
            {
              !(isAuthenticatedWith?.social || isAuthenticatedWith?.normal) ?
                <div className='nav-btn-group'>
                  <ButtonComponent
                    onClick={() => setShowForm({ login: true })}
                    style={{
                      color: 'rgba(210, 43, 43, 1)',
                      background: 'none'
                    }}
                    text={'Login'} />
                  <ButtonComponent
                    onClick={() => setShowForm({ signup: true })}
                    style={{
                      background: 'rgba(210, 43, 43, 1)',
                      color: 'rgba(255, 255, 255, 1)'
                    }}
                    text={'Signup'} />
                </div>

                :

                <div className='profile-div'>
                  <div className='notification'>
                    <img src={Images.navbar.notificationIcon} alt="notification icon" />
                  </div>
                  <div className="dropdown">
                    <div className='profile ' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src={(user && user?.picture) ? user?.picture : Images.navbar.dummyProfileImg} alt="profile-img" />
                    </div>

                    <ul className="dropdown-menu profile-dropdown">
                      <li onClick={() => navigate('profile')}>
                        <a className="dropdown-item profile-btn" href="#">
                          <div>
                            <div className='profile-icon-div ' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img className='' src={Images.navbar.profileIcon} alt="profile-img" />
                            </div>
                            <div className='dropdown-txt'>Profile</div>
                          </div>
                        </a>
                      </li>
                      <li onClick={() => navigate('my-wallet')}>
                        <a className="dropdown-item profile-btn" href="#">
                          <div>
                            <div className='profile-icon-div ' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img className='' src={Images.navbar.walletIcon} alt="wallet icon" />
                            </div>
                            <div className='dropdown-txt'>My Wallet</div>
                          </div>
                        </a>
                      </li>
                      <li onClick={() => navigate('favourite')}>
                        <a className="dropdown-item profile-btn" href="#">
                          <div>
                            <div className='profile-icon-div ' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img className='' src={Images.navbar.starIcon} alt="profile-img" />
                            </div>
                            <div className='dropdown-txt'>Favourite</div>
                          </div>
                        </a>
                      </li>
                      <li onClick={() => navigate('settings')}>
                        <a className="dropdown-item profile-btn mb-2" href="#">
                          <div>
                            <div className='profile-icon-div ' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src={Images.navbar.settingIcon} alt="profile-img" />
                            </div>
                            <div className='dropdown-txt'>Setting</div>
                          </div>
                        </a>
                      </li>
                      <li className=''>
                        <a className="dropdown-item profile-btn profile-logout-btn pt-2" href="#">
                          <div>
                            <div className='profile-icon-div ' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src={Images.navbar.logoutIcon} alt="profile-img" />
                            </div>
                            <div className='logout-txt' onClick={(e) => handleLogout(e)}>Logout</div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                </div>
            }

          </nav>
        </header>
      </div>
    </>
  )
}


function IconComponent({ onClick, icon }) {
  return <img onClick={onClick} src={icon} alt='icon' />
}