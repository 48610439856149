// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-main {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    align-items: center;
    text-align: center;
    font-family: Montserrat;
    font-size: var(--font-14px);
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    padding: 8px 0;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,2BAA2B;IAC3B,gBAAgB;IAChB,0BAA0B;IAC1B,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".footer-main {\n    background-color: rgba(255, 255, 255, 1);\n    width: 100%;\n    align-items: center;\n    text-align: center;\n    font-family: Montserrat;\n    font-size: var(--font-14px);\n    font-weight: 500;\n    color: rgba(51, 51, 51, 1);\n    padding: 8px 0;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
