import React, { useContext, useEffect, useRef, useState } from 'react'
import './LoginPopup.css'
import { Images } from '../Images/Images';
import PostRequest from '../../services/PostRequest';
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import { BetContext } from '../Context/UseContext';
import { RotatingLines } from 'react-loader-spinner';
import { LoaderComponent } from '../../Utils/Helper/HelperFunction';
import { useAuth0 } from '@auth0/auth0-react';
import { PATHS, SERVICES } from '../../config/Api_Url';



const LoginPopup = ({ showForm }) => {

    const { setIsAuthenticatedWith } = useContext(BetContext);
    const { loginWithRedirect, isAuthenticated, user } = useAuth0();

    const [otp, setOtp] = useState(new Array(4).fill(''));
    const [isPwdVisible, setIsPwdVisible] = useState({
        password: false,
        confirmPassword: false
    })
    const [formStatus, setFormStatus] = useState({
        disabled: false,
        loading: false
    })
    const inputRefs = useRef([]);
    const popupRef = useRef(null);

    const [formDisplay, setFormDisplay] = useState({
        login: false,
        signup: false,
        forgotPassword: false
    })




    useEffect(() => {
        if (isAuthenticated && user) {
            setFormDisplay({});
        }
    }, [user, isAuthenticated]);

    const handleGoogleLogin = async (e) => {
        e.preventDefault();
        await loginWithRedirect({
            authorizationParams: { connection: "google-oauth2" },
        }).then((res) => {
            console.log('google-oauth2', res);
        }).catch((err) => {
            console.log(err);
        });
    };


    useEffect(() => {
        setFormDisplay(showForm)
    }, [showForm])


    const [formData, setFormData] = useState({
        nameEmailOrMobile: '',
        username: '',
        password: '',
        confirmPassword: '',
        checkbox: false
    })


    useEffect(() => {
        const savedNameEmailOrMobile = Cookies.get("nameEmailOrMobile");
        const savedPassword = Cookies.get("password");

        if (savedNameEmailOrMobile && savedPassword) {
            setFormData({
                nameEmailOrMobile: savedNameEmailOrMobile,
                password: savedPassword,
                checkbox: true
            })
        }
    }, []);


    const handleSetForm = (name) => {
        setFormData({});
        setIsPwdVisible(false)
        setOtp(new Array(4).fill(''))
        setFormDisplay({
            [name]: true
        })
    }

    const getBody = (name) => {
        let registerBody = {
            // nameEmailOrMobile: formData.nameEmailOrMobile,
            email: formData.nameEmailOrMobile,
            username: formData.username,
            password: formData.password
        }

        let loginBody = {
            email_username: formData.nameEmailOrMobile,
            password: formData.password
        }

        let forgotBody = {
            email_username: formData.nameEmailOrMobile
        }

        if (name === 'signupForm') {
            return registerBody;
        } else if (name === 'loginForm') {
            return loginBody;
        } else if (name === 'forgotForm') {
            return forgotBody;
        }
    }

    const handleOnSubmit = async (e, name) => {
        try {
            setFormStatus({ disabled: true, loading: true });
            e.preventDefault();
            let body = getBody(name)

            if (name === 'signupForm') {
                try {
                    if (formData.password != formData.confirmPassword) {
                        return toast.warn('Password does not match');
                    }
                    let register = await PostRequest(SERVICES.accountService, PATHS.register, body)
                    console.log(register);

                    toast.success(register.data.message);
                    setFormData({});
                    setFormDisplay({});
                    setFormStatus({ disabled: false, loading: false });
                } catch (error) {
                    console.log(error);
                    setFormStatus({ disabled: false, loading: false })
                    toast.error(error.response.data.message || error.response.data.email[0]);
                }

            }
            else if (name === 'loginForm') {
                try {
                    let loginResponse = await PostRequest(SERVICES.accountService, PATHS.login, body);
                    console.log(loginResponse);
                    if (loginResponse) {

                        setIsAuthenticatedWith({ normal: true, social: false })
                        sessionStorage.setItem('api-user-data', JSON.stringify(loginResponse?.data.user));
                        toast.success(loginResponse?.data?.message);

                        // Save email and password in cookies if "Remember Me" is checked
                        if (formData.checkbox) {
                            Cookies.set("nameEmailOrMobile", formData.nameEmailOrMobile, { expires: 2 }); // Save for 2 days
                            Cookies.set("password", formData.password, { expires: 2 }); // Save for 2 days
                        } else {
                            Cookies.remove("nameEmailOrMobile");
                            Cookies.remove("password");
                        }


                        setFormData({});
                        setFormDisplay({});
                        setFormStatus({ disabled: false, loading: false });
                    }
                } catch (error) {

                    console.log(error);
                    setFormStatus({ disabled: false, loading: false });
                    toast.error(error?.response.data.message);
                }
            }

            else if (name === 'forgotForm') {
                try {
                    let forgotPassword = await PostRequest(SERVICES.accountService, PATHS.forgot_password, body)

                    toast.success(forgotPassword?.data?.message);
                    console.log(forgotPassword);
                    setFormData({});
                    setFormDisplay({});
                    setFormStatus({ disabled: false, loading: false });
                } catch (error) {
                    console.log(error);
                    setFormStatus({ disabled: false, loading: false });
                    toast.error(error?.response.data.message);
                }
            }

        } catch (error) {
            console.log('handleOnSubmit error', error);
            setFormStatus({ disabled: false, loading: false });
        }
    };

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'checkbox' ? checked : value
        })
    }


    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move focus to next input if not the last
            if (index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            const newOtp = [...otp];
            if (index > 0 && !otp[index]) {
                newOtp[index - 1] = '';
                setOtp(newOtp);
                inputRefs.current[index - 1].focus(); // Move focus back
            } else {
                newOtp[index] = ''; // Clear current field
                setOtp(newOtp);
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').slice(0, otp.length); // Limit to OTP length

        const newOtp = pastedData.split('').map((char, i) => (/^[0-9]$/.test(char) ? char : otp[i]));

        setOtp(newOtp);

        // Focus on the last filled input or submit button
        const nextIndex = pastedData.length - 1;
        if (nextIndex < otp.length) {
            inputRefs.current[nextIndex].focus();
        } else {
            inputRefs.current[otp.length - 1].focus();
        }
    };

    const handleFocus = (e) => {
        e.target.select();
    };

    let addpadding = (formDisplay.login || formDisplay.forgotPassword) ? 'add-more-padding' : ''
    let addMt = (formDisplay.login || formDisplay.forgotPassword) ? 'notsignup' : ''

    useEffect(() => {
        const handleClickOutside = (event) => {
            const toastElement = document.querySelector('.Toastify');

            if (popupRef.current && !popupRef.current.contains(event.target) && !(toastElement && toastElement.contains(event.target))) {
                setFormDisplay({})
                setFormData({});
            }
        };

        if (formDisplay.login || formDisplay.signup || formDisplay.forgotPassword) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [formDisplay]);


    if (formDisplay.login || formDisplay.forgotPassword || formDisplay.signup) return (
        <div className='loginPopup-form-sec-div' >
            <div ref={popupRef} className={`under-sec-div-loginPopup  ${formDisplay.signup ?? 'addMoreRight'}`}>
                <div className={`sec-div-border-loginPopup ${addpadding}`}>
                    <div className='red-cut-icon' onClick={() => setFormDisplay({ login: false, signup: false, forgotPassword: false })}>
                        <img src={Images.redCutIcon} alt="redcut-icon" />
                    </div>

                    {/* <div className='loginPopup-form-logo'>
                            <img className='logo' src={Images.logoWithText} alt="logo" />
                        </div> */}
                    {
                        formDisplay.login &&
                        <>
                            <div className='loginPopup-div'>
                                <div>Login</div>
                                <p>Please fill your detail to access your account.</p>
                            </div>

                            <form className={`login-popup-form ${addMt}`} onSubmit={(e) => handleOnSubmit(e, `loginForm`)}>
                                <div className='loginPopup-email'>
                                    <p>User name, email or mobile number</p>
                                    <input type="text" name='nameEmailOrMobile' value={formData.nameEmailOrMobile || ''} onChange={(e) => handleChange(e)} required className='loginPopup-input' placeholder='Enter user name, email or mobile number' />
                                    {formData.nameEmailOrMobile ? (
                                        <span className="input-icon" onClick={() => setFormData({ ...formData, nameEmailOrMobile: '' })}>
                                            <img src={Images.cutIcon} alt="Clear input" />
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                                <div className='loginPopup-password'>
                                    <p>Password</p>
                                    <input type={isPwdVisible.password ? 'text' : 'password'} name='password' value={formData.password || ''} onChange={(e) => handleChange(e)} required placeholder='Enter your password' />
                                    <span className='input-icon' onClick={() => setIsPwdVisible({ ...isPwdVisible, ['password']: !isPwdVisible.password })}><img src={Images.eyeIcon} alt='icon' /></span>
                                </div>

                                <div className="loginPopup-form-check form-check">
                                    <input name='checkbox' disabled={formStatus.disabled} value={formData.checkbox || ''} onChange={(e) => handleChange(e)} className="form-check-input m-0" type="checkbox" id="flexCheckChecked" />
                                    <div className='check-para'>
                                        <p className='remember-me'>Remember me</p>
                                        <p className='forgot-Password' onClick={() => handleSetForm(`forgotPassword`)}>Forgot Password?</p>
                                    </div>
                                </div>

                                <div className='loginPopup-btn-div'>
                                    <button disabled={formStatus.disabled} className='loginPopup-btn' type='submit' >
                                        <span>Log in</span>
                                        <span className='px-2'>
                                            <LoaderComponent isLoading={formStatus.loading} />
                                        </span>
                                    </button>
                                </div>

                                <div className='google-btn-div'>
                                    <button onClick={(e) => handleGoogleLogin(e)} disabled={formStatus.disabled} className='google-btn'>
                                        <span><img className='google-img' src={Images.google} alt="google icon" /></span>
                                        <span className='google-text' > Log in with Google </span>
                                    </button>
                                </div>

                                <div className='dont-acc-div'>
                                    <span className='dont-acc'>Don’t have an account?</span>
                                    <span className='sign-up' onClick={() => handleSetForm(`signup`)}>Sign up</span>
                                </div>

                            </form>
                        </>
                    }

                    {
                        formDisplay.signup &&
                        <>
                            <div className='loginPopup-div'>
                                <div>Sign Up</div>
                                <p>Please fill your detail to create your account.</p>
                            </div>

                            <form className='login-popup-form' onSubmit={(e) => handleOnSubmit(e, `signupForm`)}>
                                <div className='loginPopup-email'>
                                    <p>Email Or Phone Number</p>
                                    <input disabled={formStatus.disabled} type="text" name='nameEmailOrMobile' value={formData.nameEmailOrMobile || ''} onChange={(e) => handleChange(e)} required className='loginPopup-input' placeholder='Enter email or mobile number' />
                                    {formData.nameEmailOrMobile ? (
                                        <span className="input-icon" onClick={() => setFormData({ ...formData, nameEmailOrMobile: '' })}>
                                            <img src={Images.cutIcon} alt="Clear input" />
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                                <div className='loginPopup-email mt-2'>
                                    <p>User Name</p>
                                    <input disabled={formStatus.disabled} type="text" name='username' value={formData.username || ''} onChange={(e) => handleChange(e)} required className='loginPopup-input' placeholder='Enter a user name' />
                                    {formData.username ? (
                                        <span className="input-icon" onClick={() => setFormData({ ...formData, username: '' })}>
                                            <img src={Images.cutIcon} alt="Clear input" />
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                                <div className='loginPopup-password'>
                                    <p>Password</p>
                                    <input disabled={formStatus.disabled} type={isPwdVisible.password ? 'text' : 'password'} name='password' value={formData.password || ''} onChange={(e) => handleChange(e)} required placeholder='Enter password' />
                                    <span className='input-icon' onClick={() => setIsPwdVisible({ ...isPwdVisible, ['password']: !isPwdVisible.password })} ><img src={Images.eyeIcon} alt='icon' /></span>
                                </div>
                                <div className='loginPopup-password'>
                                    <p>Confirm Password</p>
                                    <input disabled={formStatus.disabled} type={isPwdVisible.confirmPassword ? 'text' : 'password'} name='confirmPassword' value={formData.confirmPassword || ''} onChange={(e) => handleChange(e)} required placeholder='Enter password again' />
                                    <span className='input-icon' onClick={() => setIsPwdVisible({ ...isPwdVisible, ['confirmPassword']: !isPwdVisible.confirmPassword })} ><img src={Images.eyeIcon} alt='icon' /></span>
                                </div>

                                <div className="loginPopup-form-check form-check">
                                    <input disabled={formStatus.disabled} name='checkbox' value={formData.checkbox || ''} onChange={(e) => handleChange(e)} className="form-check-input m-0" type="checkbox" id="flexCheckChecked" />
                                    <div className='terms-div'>
                                        <p className='remember-me'>I agree with the</p>
                                        <p className='forgot-Password'>Terms of Conditions</p>
                                    </div>
                                </div>

                                <div className='loginPopup-btn-div'>
                                    <button className='loginPopup-btn' type='submit' >Sign Up</button>
                                </div>

                                <div className='google-btn-div'>
                                    <button className='google-btn' onClick={(e) => handleGoogleLogin(e)}>
                                        <span><img className='google-img' src={Images.google} alt="google icon" /></span>
                                        <span className='google-text' >Sign Up with Google</span>
                                    </button>
                                </div>

                                <div className='dont-acc-div'>
                                    <span className='dont-acc'>Already have an account?</span>
                                    <span className='sign-up' onClick={() => handleSetForm(`login`)}>Login</span>
                                </div>

                            </form>
                        </>
                    }

                    {
                        formDisplay.forgotPassword &&
                        <>
                            <div className='loginPopup-div'>
                                <div>Forgot Password</div>
                                <p className='mx-1 my-2'>Please enter the registered mobile number or email for otp</p>
                            </div>

                            <form className='login-popup-form' onSubmit={(e) => handleOnSubmit(e, `forgotForm`)}>
                                <div className='loginPopup-email'>
                                    <p>Email Or Phone Number</p>
                                    <input disabled={formStatus.disabled} type="text" name='nameEmailOrMobile' value={formData.nameEmailOrMobile || ''} onChange={(e) => handleChange(e)} required className='loginPopup-input' placeholder='Enter user name, email or mobile number' />
                                    {formData.nameEmailOrMobile ? (
                                        <span className="input-icon" onClick={() => setFormData({ ...formData, nameEmailOrMobile: '' })}>
                                            <img src={Images.cutIcon} alt="Clear input" />
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>

                                {/* <div className='loginPopup-email mt-3'>
                                        <p>OTP</p>
                                        <form className='mt-1' id="otp-form">
                                            <div className="otp-form-div">
                                                {otp.map((_, index) => (
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        value={otp[index]}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                                        onFocus={handleFocus}
                                                        onPaste={handlePaste}
                                                        maxLength="1"
                                                        ref={(el) => (inputRefs.current[index] = el)} // Set ref for each input
                                                        placeholder='_'
                                                        className='otp-input'
                                                    />
                                                ))}
                                            </div>
                                        </form>
                                    </div>

                                    <div className="loginPopup-form-check form-check">
                                        <div className='terms-div'>
                                            <p className='forgot-Password'>Resend OTP</p>
                                        </div>
                                    </div> */}

                                <div className='loginPopup-btn-div mt-3'>
                                    <button className='loginPopup-btn' type='submit' >Login</button>
                                </div>

                                <div className='dont-acc-div'>
                                    <span className='sign-up' onClick={() => handleSetForm(`login`)}>Back To Login</span>
                                </div>

                            </form>
                        </>
                    }


                </div>
            </div>
        </div >
    )
}

export default LoginPopup