
const auth0Config = {
    domain: "dev-bm4562c6puztitfi.us.auth0.com",
    clientId: "uoIIhYtSbaYbj23VJYst5YlBX9vY2PtP",
    audience: "https://dev-bm4562c6puztitfi.us.auth0.com/api/v2/",
    // redirectUri: "http://localhost:3000",
    redirectUri: window.location.origin === 'http://localhost:3000' ? 'http://localhost:3000/home/all' : 'https://user.akalabet.com/home/all',
    responseType: 'token',
    scope: 'openid profile email',
}
export default auth0Config;