import React from "react";
import Chart from "react-apexcharts";
import { isMobileWidth } from "../../Utils/Helper/HelperFunction";

const RadialBarChart = ({ currentOdds, initialOdds }) => {

  const options = {
    chart: {
      height: 60,
      width: 60,
      type: 'radialBar',
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '45%',
          color: undefined,
        },
        track: {
          background: '#e7e7e7',
        },
        dataLabels: {
          show: true,
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: isMobileWidth ? '14px' : '12px',
            offsetY: 5,
            formatter: function (val) {
              return `${val}%`;
            },
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    colors: [getChartColor(currentOdds)],
    labels: ['Progress'],
  };

  const series = [currentOdds || 0];

  return (
    <div style={{ width: '60px', height: '60px' }}>
      <Chart options={options} series={series} type="radialBar" height="100%" width="100%" />
    </div>
  );
};

export default RadialBarChart;

const getChartColor = (currentOdds) => {
  if (currentOdds > 45) {
    return 'rgba(28, 121, 71, 1)';
  } else if (currentOdds < 45 && currentOdds > 30) {
    return 'rgba(228, 181, 40, 1)';
  } else {
    return 'rgba(210, 43, 43, 1)';
  }
}