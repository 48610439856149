import axios from "axios";
import { API_URL } from "../config/Api_Url";
import { useSessionStorageData } from "../Utils/Helper/HelperFunction";

const PostRequest = async (service, path, data = {}) => {
  let sessionStorageData = useSessionStorageData();
  let token = sessionStorageData?.googleAccessToken || sessionStorageData?.apiUserData?.tokens?.access?.token
  
  const addBearerToken = (headers, token) => {
    
    let removeCoutes = String(token).replace(/"/g, '');

    if (!token.startsWith('Bearer ')) {
      token = `Bearer ${removeCoutes}`;
    }

    headers.Authorization = token;

    return headers;
  };

  try {

    let header = {};

    if (token) {
      header = addBearerToken(header, token);
    }

    if (path != 'profile') {
      header["content-type"] = "application/json";
    }

    const response = await axios.post(`${service}/${path}/`, data, {
      headers: header,
    });

    return response;
  } catch (error) {
    throw error
  }
};

export default PostRequest;
