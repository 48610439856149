import React, { useEffect, useState, useRef } from 'react';
import './Crousel.css';
import { Images } from '../Images/Images';
import { ButtonComponent, isMobileWidth } from '../../Utils/Helper/HelperFunction';

export default function Crousel() {
    const [index, setIndex] = useState(1); // Start with the first real slide
    const [isHovered, setIsHovered] = useState(false);
    const [slideClass, setSlideClass] = useState('slide-in');
    const sliderContainerRef = useRef(null);

    const slides = [
        {
            image: Images.crousel.bannerOne,
            heading: 'This Action Movie',
            paragraph: 'Flop or Hit?',
            buttonText: 'Bet Now!'
        },
        {
            image: Images.crousel.bannerOne,
            heading: 'This Action Movie',
            paragraph: 'Flop or Hit?',
            buttonText: 'Bet Now!'
        },
        {
            image: Images.crousel.bannerOne,
            heading: 'This Action Movie',
            paragraph: 'Flop or Hit?',
            buttonText: 'Bet Now!'
        },
        {
            image: Images.crousel.bannerOne,
            heading: 'This Action Movie',
            paragraph: 'Flop or Hit?',
            buttonText: 'Bet Now!'
        },
    ];

    const totalSlides = slides.length;
    // const slidesWithDuplicates = [slides[totalSlides - 1], ...slides, slides[0]];

    // Handle automatic sliding
    // useEffect(() => {
    //     if (!isHovered) {
    //         const interval = setInterval(() => {
    //             slideRight();
    //         }, 3000);
    //         return () => clearInterval(interval);
    //     }
    // }, [isHovered]);

    // Slide to the next image
    const slideRight = () => {
        setSlideClass('slide-in')
        setIndex(prevIndex => (prevIndex + 1) % (totalSlides + 2));
    };

    // Slide to the previous image
    const slideLeft = () => {
        setSlideClass('slide-out')
        setIndex(prevIndex => (prevIndex - 1 + totalSlides + 2) % (totalSlides + 2));
    };

    // Handle bullet navigation
    const goToSlide = (slideIndex) => {
        if (index > slideIndex) {
            setSlideClass('slide-out')
            setIndex(slideIndex + 1);
        } else {
            setSlideClass('slide-in')
            setIndex(slideIndex + 1);
        }
    };

    // Adjust index for seamless transition
    useEffect(() => {
        if (index === 0) {
            setTimeout(() => setIndex(totalSlides), 0);
        } else if (index === totalSlides + 1) {
            setTimeout(() => setIndex(1), 0);
        }
    }, [index, totalSlides]);

    return (
        <div>
            <section id="container">
                <div
                    id="slider-container"
                    ref={sliderContainerRef}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {/* {slides.map((slide, slideIndex) => (
                        <div className='banner-heading'>
                            <p>{slide.heading}</p>
                            <p>{slide.paragraph}</p>
                            <ButtonComponent
                                text={slide.buttonText}
                                style={{
                                    background: 'rgba(210, 43, 43, 1)',
                                    color: 'rgba(255, 255, 255, 1)',
                                    fontSize: '12px',
                                    marginTop: '5px'
                                }}
                            />
                        </div>
                    ))} */}
                    <ul className="images-container" >
                        {slides.map((slide, slideIndex) => (
                            <>
                                {slideIndex === (index - 1) && (
                                    <>
                                        <li style={{ width: '100%' }}>
                                            <img src={slide.image} alt={`Slide ${index}`} className={slideClass} />
                                        </li>
                                        <div className={`banner-heading  ${slideClass}`}>
                                            <p>{slide.heading}</p>
                                            <p>{slide.paragraph}</p>
                                            <ButtonComponent
                                                text={slide.buttonText}
                                                style={{
                                                    background: 'rgba(210, 43, 43, 1)',
                                                    color: 'rgba(255, 255, 255, 1)',
                                                    fontSize: isMobileWidth ? '8px' : '12px',
                                                    margin: isMobileWidth ? '0px' : '5px 0',
                                                    padding: isMobileWidth ? '0px 10px' : '5px 30px'
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        ))}
                    </ul>

                    {/* <span className="arrow a-left" onClick={slideLeft}>
                        &#8249;
                    </span>
                    <span className="arrow a-right" onClick={slideRight}>
                        &#8250;
                    </span> */}

                    <div className="bullets-container">
                        {slides.map((_, slideIndex) => (
                            <span
                                key={slideIndex}
                                className={`bullet ${index === slideIndex + 1 ? 'active' : ''}`}
                                onClick={() => goToSlide(slideIndex)}
                            ></span>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};
