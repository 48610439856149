// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-category-btns {
    display: flex;
}

.home-category-btns {
    overflow-x: scroll;
    white-space: nowrap;
}

.home-category-btns::-webkit-scrollbar {
    display: none;
}

.home-category-btns {
    scrollbar-width: none;
}

.home-category-btns {
    height: auto;
    padding-bottom: 10px;
}
.cards-home{
    margin-top: 1rem;
}
.nav-btn.active{
    background: rgba(65, 105, 225, 0.1);
    color: rgba(65, 105, 225, 1);
}
@media screen and (max-width: 768px) {
    /* .home-category-btns {
        margin: 10px 0;
    } */
    .cards-home{
        margin-top: 0;
    }
    .banner-heading p:nth-child(2) {
        font-size: 1.2rem;
        margin: 0;
    }
    .banner-heading p:first-child {
        font-size: 0.8rem;
    }
    .banner-heading {
        top: 10%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,mCAAmC;IACnC,4BAA4B;AAChC;AACA;IACI;;OAEG;IACH;QACI,aAAa;IACjB;IACA;QACI,iBAAiB;QACjB,SAAS;IACb;IACA;QACI,iBAAiB;IACrB;IACA;QACI,QAAQ;IACZ;AACJ","sourcesContent":[".home-category-btns {\n    display: flex;\n}\n\n.home-category-btns {\n    overflow-x: scroll;\n    white-space: nowrap;\n}\n\n.home-category-btns::-webkit-scrollbar {\n    display: none;\n}\n\n.home-category-btns {\n    scrollbar-width: none;\n}\n\n.home-category-btns {\n    height: auto;\n    padding-bottom: 10px;\n}\n.cards-home{\n    margin-top: 1rem;\n}\n.nav-btn.active{\n    background: rgba(65, 105, 225, 0.1);\n    color: rgba(65, 105, 225, 1);\n}\n@media screen and (max-width: 768px) {\n    /* .home-category-btns {\n        margin: 10px 0;\n    } */\n    .cards-home{\n        margin-top: 0;\n    }\n    .banner-heading p:nth-child(2) {\n        font-size: 1.2rem;\n        margin: 0;\n    }\n    .banner-heading p:first-child {\n        font-size: 0.8rem;\n    }\n    .banner-heading {\n        top: 10%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
