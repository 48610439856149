import React from 'react'
import { ButtonComponent } from '../../Utils/Helper/HelperFunction'
import { Images } from '../Images/Images'
import './MyWallet.css'
import { DataTable } from '../DataTable/DataTable'

export const MyWallet = () => {

    const headers = [
        'Transaction ID',
        'Currency',
        'Date',
        'Type',
        'Amount',
        'Conversion Rate',
        'Amount (₱)',
        'Status',
    ];

    const data = [
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Pending',
        },
        {
            tx_id: '#TX67890',
            currency: 'USDT',
            date: '12/08/2024',
            deposit: 'Deposit',
            amount: {
                icon: (<span><img src={Images.myWallet.usdtIcon} alt="usdt icon" /></span>),
                amount: '23',
            },
            conversionRate: '1 USDT = ₱50',
            amountInPeso: '₱2,500',
            status: 'Completed',
        },
    ];

    return (
        <div>

            <div className='my-wallet-main'>

                <div className='row' >
                    <div className='col-md-8  white-bg avail-first-div rem-wid-one'>
                        <div className='under-avail-first-div'>
                            <div className='av-bal'>

                                <div className='txt'>Available Balance</div>
                                <div className='amt'>₱ 12,500</div>
                            </div>
                            <div>
                                <ButtonComponent
                                    onClick={() => { }}
                                    style={{
                                        color: 'rgba(210, 43, 43, 1)',
                                        background: 'rgba(228, 181, 40, 1)',
                                        border: 'none',
                                        borderRadius: '6px'
                                    }}
                                    text={
                                        <div className='add-money-div'>
                                            <span>
                                                <img src={Images.myWallet.plusIcon} alt="Plus Icon" />
                                            </span>
                                            <span>Add Money</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div className='under-avail-sec-div'>
                            <div className='txt'>Address</div>
                            <div className='wallet-adr'>0x4CA8FF586cC19b1e8D3</div>
                            <div className='img'>
                                <img src={Images.myWallet.copyIcon} alt="Copy Icon" />
                            </div>

                        </div>
                    </div>
                    <div className='col-md-4 white-bg with-mon-main rem-wid-two'>

                        <ButtonComponent
                            onClick={() => { }}
                            style={{
                                color: 'rgba(210, 43, 43, 1)',
                                background: 'rgba(28, 121, 71, 1)',
                                border: 'none',
                                borderRadius: '6px'
                            }}
                            text={
                                <div className='add-money-div'>
                                    <span>
                                        <img src={Images.myWallet.withdrawMoney} alt="Plus Icon" />
                                    </span>
                                    <span>Withdraw Money</span>
                                </div>
                            }
                        />

                        <ButtonComponent
                            onClick={() => { }}
                            style={{
                                color: 'rgba(210, 43, 43, 1)',
                                background: 'rgba(65, 105, 225, 1)',
                                border: 'none',
                                borderRadius: '6px'
                            }}
                            text={
                                <div className='add-money-div'>
                                    <span>
                                        <img src={Images.myWallet.redeemCoupon} alt="Plus Icon" />
                                    </span>
                                    <span>Redeem Coupon</span>
                                </div>
                            }
                        />
                    </div>
                </div>

                <div className="table my-3">
                    <DataTable headers={headers} data={data} itemsPerPage={10} />
                </div>
            </div>
        </div>
    )
}
