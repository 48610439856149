// const DOMAIN = "https://account.backend.akalabet.com";
// http://13.238.98.169:9000/account-service-api/v1/auth/register
// const DOMAIN = "https://4p7pzchd-8000.inc1.devtunnels.ms";
// export const API_URL = `${DOMAIN}/account-service-api/v1/auth`;

export const SERVICES = {
    accountService: 'https://account.backend.akalabet.com/account-service-api/v1/auth',
    eventService: 'https://eventservices-backend.akalabet.com/event-service-api/v1/events',
    // eventService: 'http://192.168.0.86:8000/event-service-api/v1/events',
    bettingService: 'https://betting-backend.akalabet.com/betting_engine-service-api/v1/bet',
    paymentService: 'http://13.238.98.169:4001/payment-service-api/v1/payment',
}

export const PATHS = {
    register: 'register',
    login: 'login',
    forgot_password: 'forgot-password',
    socialLogin: 'social-login',
    active_events: 'active_events',
    profile: 'profile',
    eventDetail: 'event_by_id',
    place_bet: 'place_bets',
    get_bets: 'get_bets',
    add_balance: 'add_balance',
}