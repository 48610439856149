import React, { useContext, useEffect, useState } from 'react'
import './Settings.css'
import { Images } from '../Images/Images'
import { ButtonComponent, LoaderComponent } from '../../Utils/Helper/HelperFunction'
import GetRequest from '../../services/GetRequest'
import { PATHS, SERVICES } from '../../config/Api_Url'
import PostRequest from '../../services/PostRequest'
import { toast } from 'react-toastify'
import { BetContext } from '../Context/UseContext'

export const Settings = () => {

    const [pageVisible, setPageVisible] = useState('profile')
    const [selectedImage, setSelectedImage] = useState({ preview: null, file: null }); // For handling the new selected image
    const [username, setUserName] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const { isAuthenticatedWith, profile, setProfile } = useContext(BetContext)


    const getProfile = async () => {
        try {

            let profile = await GetRequest(SERVICES.accountService, PATHS.profile);
            setProfile(profile.data.data);
        } catch (error) {
            console.log('profile error: ', error);
        }

    }

    useEffect(() => {
        getProfile()
    }, [isAuthenticatedWith])

    const updateProfile = async () => {
        setIsLoading(true);
        try {

            let formdata = new FormData();
            formdata.append('username', username);
            // Check if an image file is selected
            if (selectedImage.file) {
                formdata.append('image', selectedImage.file);  // Append the image file only if it exists
            } else {
                console.log('No image file selected');
            }

            let updatedProfile = await PostRequest(SERVICES.accountService, PATHS.profile, formdata);
            toast.success(updatedProfile.data.mesage)
            getProfile()
        } catch (error) {
            console.log('error: ', error);
            toast.error('An error occured');

        } finally {
            setIsLoading(false);
        }

    }

    // Handle image selection
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage({ preview: URL.createObjectURL(file), file: file });
        }
    }

    // Handle "Change Photo" click
    const handleChangePhotoClick = () => {
        document.getElementById('file-input').click(); // Trigger file input click
    }
    return (
        <div className='setting-main'>
            <div className='set-txt'>Settings</div>
            <div className='st-main'>

                <div className="setting-side">

                    <div className={`set-pro ${pageVisible == 'profile' ? 'active' : ''}`} onClick={() => setPageVisible('profile')}>
                        <div className="pro-img">
                            <img src={pageVisible == 'profile' ? Images.profile.redProfileIcon : Images.navbar.profileIcon} alt="profie-img" />
                        </div>
                        <div className='txt'>Profile</div>
                    </div>
                    <div className={`set-pro ${pageVisible == 'notification' ? 'active' : ''}`} onClick={() => setPageVisible('notification')}>
                        <div className="pro-img">
                            <img src={pageVisible == 'notification' ? Images.profile.redNotificationIcon : Images.navbar.notificationIcon} alt="profie-img" />
                        </div>

                        <div className='txt'>Notification</div>
                    </div>
                </div>
                <div className="setting-box">
                    {
                        (pageVisible === 'profile') ?

                            <div>
                                <div className="pro-up">
                                    <div>Profile</div>
                                    <div>Update your profile</div>
                                </div>

                                <div className='change-pro-img'>
                                    <div className='img-pro'>
                                        <img src={selectedImage.preview || profile?.image || Images.profile.profileImg} alt="profile-image" />
                                    </div>
                                    <div className="change-photo" onClick={handleChangePhotoClick}>
                                        Change Photo
                                    </div>
                                    <input
                                        type="file"
                                        id="file-input"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>

                                <div className='pro-email'>
                                    <div className="email">Email</div>
                                    <div className="e-txt">{profile?.email || 'john@gmail.com'}</div>
                                </div>
                                <div className='pro-email'>
                                    <div className="email">User Name</div>
                                    <input value={username} onChange={(e) => setUserName(e.target.value)} placeholder={profile?.username || 'John Doe'} type="text" name="" id="" />
                                </div>

                                <div className="act-btn">
                                    <ButtonComponent
                                        style={{
                                            background: 'rgba(255, 255, 255, 1)',
                                            color: 'rgba(210, 43, 43, 1)',
                                            borderRadius: '100px',
                                            fontSize: '12px',
                                            padding: '8px 24px',
                                            margin: '0'
                                        }}
                                        text={'Cancel'} />
                                    <ButtonComponent
                                        onClick={updateProfile}
                                        style={{
                                            background: 'rgba(210, 43, 43, 1)',
                                            color: 'rgba(255, 255, 255, 1)',
                                            borderRadius: '100px',
                                            fontSize: '12px',
                                            padding: '8px 24px',
                                            margin: '0',
                                            display: 'flex',
                                            alignItem: 'cemter'
                                        }}
                                        isDisabled={isLoading}
                                        text={
                                            <>
                                                Save Changes
                                                <LoaderComponent isLoading={isLoading} />
                                            </>
                                        } />

                                </div>
                            </div>

                            :
                            <div>

                                <div className='not-bar'>
                                    <div className="not-txt">Notification</div>
                                    <div className="not-para">Customize How You Stay Informed and Connected</div>
                                </div>
                                <div className='not-bar-email-main'>
                                    <div className='not-bar-email'>
                                        <div className="not-txt">Get Notification by Email</div>
                                        <div className="not-para">Get every update of your bets and account via email.</div>
                                    </div>
                                    <div class="togle">
                                        <input className='toggle-input' type="checkbox" id="SwitchOne" checked /><label className='togle-label' for="SwitchOne">Toggle</label>
                                    </div>
                                </div>
                                <div className='not-bar-email-main'>
                                    <div className='not-bar-email'>
                                        <div className="not-txt">Security Alert</div>
                                        <div className="not-para">Be alerted of any unusual activity on your account.</div>
                                    </div>
                                    <div class="togle">
                                        <input className='toggle-input' type="checkbox" id="switchTwo" /><label className='togle-label' for="switchTwo">Toggle</label>
                                    </div>
                                </div>
                                <div className="act-btn add-pad">
                                    <ButtonComponent
                                        style={{
                                            background: 'rgba(255, 255, 255, 1)',
                                            color: 'rgba(210, 43, 43, 1)',
                                            borderRadius: '100px',
                                            fontSize: '12px',
                                            padding: '8px 24px',
                                            margin: '0'
                                        }}
                                        text={'Cancel'} />
                                    <ButtonComponent
                                        style={{
                                            background: 'rgba(210, 43, 43, 1)',
                                            color: 'rgba(255, 255, 255, 1)',
                                            borderRadius: '100px',
                                            fontSize: '12px',
                                            padding: '8px 24px',
                                            margin: '0'
                                        }}
                                        text={'Save Changes'} />

                                </div>
                            </div>
                    }

                </div>


            </div>
        </div>
    )
}
