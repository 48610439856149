
import React, { useState } from 'react'
import './Cards.css'
import { Images } from '../Images/Images'
import RadialBarChart from '../RadialBarChart/RadialBarChart'
import { ButtonComponent, formatAndLowerPath, formatPathName, getUrlDetails } from '../../Utils/Helper/HelperFunction'
import { useLocation, useNavigate } from 'react-router-dom'

export const Cards = ({ event }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const { pageName, category } = getUrlDetails(location.pathname);

    const [selectedBet, setSelectedBet] = useState({
        optionID: '',
        isNegative: false,
        isPositive: false,
        selectedOptionDetail: null
    });
    
    const handleCardClick = (e) => {
        if (!e.target.closest('.bet-btn') && !e.target.closest('.Yes') && !e.target.closest('.No')) {
            navigate(`/${pageName}/${category}/event-detail/${event._id}`, {
                state: { eventData: selectedBet },
            });
        }
    };

    const handleBetSelection = ({ optionID, selectedOptionDetail }) => {
        
        setSelectedBet(prev => ({
            ...prev,
            optionID: optionID,
            selectedOptionDetail: selectedOptionDetail
        }));
    };

    const handleConfirmationClick = (isPositive) => {
        const updatedBet = {
            ...selectedBet,
            isPositive: isPositive,
            isNegative: !isPositive,
        };

        navigate(`/${pageName}/${category}/event-detail/${event._id}`, {
            state: { eventData: updatedBet },
        });
    };



    return (
        <div
            onClick={handleCardClick}
            className='col-md-6 fix-width'
        >

            <div className='card-main'>
                <div className='card-main-one'>
                    <div className='image-div'>
                        <img src={event?.image || ''} alt="card-image" />
                    </div>
                    <div>{event?.title || ''}</div>
                </div>

                <div className='card-chance-bar-main'>
                    {
                        event.options.map((option, index) => {
                            return (
                                <div className='under'>
                                    <div className='chance-para'>{option.title}</div>

                                    <div className="chance-bar">
                                        <RadialBarChart currentOdds={option.currentOdds} initialOdds={option.initialOdds} />
                                        <div className='chances'>Chances</div>
                                    </div>
                                    <div className='bet-btn'>
                                        <ButtonComponent
                                            text={'BET'}
                                            onClick={() => handleBetSelection({ optionID: option._id || '_id', selectedOptionDetail: option })} // Handle bet selection
                                            value={selectedBet.optionID}
                                            isActive={selectedBet.optionID === option._id ? 'selected' : ''}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

                <div className='card-buttons'>
                    <button className={`Yes ${selectedBet.isPositive ? 'selected' : ''}`} onClick={() => handleConfirmationClick(true)}>{event.positive_text || 'Yes'}</button>
                    <button className={`No  ${selectedBet.isNegative ? 'selected' : ''}`} onClick={() => handleConfirmationClick(false)}> {event.negative_text || 'No'}</button>
                </div>


                <div className='card-footer-main'>
                    <div className='card-footer-one'>
                        <div>
                            <img src={Images.cards.parking} alt="parking image" />
                        </div >
                        <div className='bet-count'>474.84K  Bet</div>
                    </div>

                    <div className='card-like-comment'>

                        <div className='comment-img'>
                            <img src={Images.cards.comment} alt="comment image" />
                        </div>
                        <div className='comment-txt'>23</div>
                        <div className='comment-img'>
                            <img src={Images.cards.star} alt="comment image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



// import React from 'react'
// import './Cards.css'
// import { Images } from '../Images/Images'
// import RadialBarChart from '../RadialBarChart/RadialBarChart'
// import { useNavigate } from 'react-router-dom'

// export const Cards = ({ event }) => {
//     const navigate = useNavigate();
//     return (
//         <div onClick={() =>navigate('card-detail')} className='col-md-6 fix-width'>
//             <div className='card-main'>
//                 <div className='card-main-one'>
//                     <div className='image-div'>
//                         <img src={event?.image || ''} alt="card-image" />
//                     </div>
//                     <div>{event?.title || ''}</div>
//                 </div>

//                 <div className='card-chance-bar-main'>
//                     <div className='chance-para'>{( event?.options?.length > 0 ? event.options[0].title : '') || ''}</div>

//                     <div className="chance-bar">
//                         <RadialBarChart />
//                         <div className='chances'>Chances</div>
//                     </div>

//                 </div>

//                 <div className='card-buttons'>
//                     <button className='Yes'>{'Yes'}</button>
//                     <button className='No'>{'No'}</button>
//                 </div>


//                 <div className='card-footer-main'>
//                     <div className='card-footer-one'>
//                         <div>
//                             <img src={Images.cards.parking} alt="parking image" />
//                         </div >
//                         <div className='bet-count'>474.84K  Bet</div>
//                     </div>

//                     <div className='card-like-comment'>

//                         <div className='comment-img'>
//                             <img src={Images.cards.comment} alt="comment image" />
//                         </div>
//                         <div className='comment-txt'>23</div>
//                         <div className='comment-img'>
//                             <img src={Images.cards.star} alt="comment image" />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }