import React, { useEffect, useState } from 'react'
import './Sports.css'
import { ButtonComponent, CategoryButtonsComponent, formatAndLowerPath, getUrlDetails, SortingDropdownComponent } from '../../Utils/Helper/HelperFunction'
import Pagination, { PaginatedComponent } from '../../Components/Pagination/Pagination'
import { Images } from '../../Components/Images/Images'
import { DummyCardsOne } from '../../Components/Cards/DummyCardsOne'
import GetRequest from '../../services/GetRequest'
import { PATHS, SERVICES } from '../../config/Api_Url'
import { useLocation, useNavigate } from 'react-router-dom'
import { Cards } from '../../Components/Cards/Cards'

export const Sports = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pageName, category: urlCategory } = getUrlDetails(location.pathname);
  
    const [events, setEvents] = useState([]);
    const [category, setCategory] = useState({ key: 'SPORT', value: 'Sport' });
    const [sort, setSort] = useState({ key: 'ALL', value: 'All' });
    const [searchTerm, setSearchTerm] = useState('');
    const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1 });
    const buttons = {
        ALL: 'All',
        FOOTBALL: 'Football',
        CRICKET: 'Cricket',
        TENNIS: 'Tennis',
        BOXING: 'Boxing',
        BASKETBALL: 'Basketball',
        BASEBALL: 'Baseball'
    }
    const soringButtons = {
        ALL: 'All',
        NEW: 'New',
        TOP: 'Top',
        TRENDING: 'Trending',
        ENDING_SOON: 'Ending Soon'
    }

    const getAllEvents = async (category = 'ALL', page = 1) => {
        try {
            let params = { page: page, per_page: 10 }
            let events = await GetRequest(SERVICES.eventService, `${PATHS.active_events}/${category}`, params);

            setPagination({
                currentPage: page,
                totalPages: events?.data.data.total_pages || 1,
            });
            setEvents(events?.data.data.events || [])
        } catch (error) {
            console.log('getAllEvents error: ', error);

        }
    }

    useEffect(() => {
        getAllEvents(category.key, pagination.currentPage);
    }, [category, pagination.currentPage]);

    const handlePageChange = (pageNumber) => {
        setPagination((prev) => ({
            ...prev,
            currentPage: pageNumber,
        }));
    };

    const handleCategoryChange = ({ key, value }) => {
        setCategory({ key, value });
        navigate(`/${pageName}/${formatAndLowerPath(value)}`)
    };
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        console.log('Searching for:', searchTerm);
    };
    const handleSortChange = ({ key, value }) => {
        setSort({ key, value });
    };
    return (
        <div className='mt-4 sports-main'>
            <div className=' sports-btn-dropsdown'>
                <div className='sports-btn'>
                    <CategoryButtonsComponent onClick={(e) => handleCategoryChange(e)} buttons={Object.entries(buttons)} isActive={category.value} />
                </div>
                <div className='sports-grp-search-sort'>
                    <div className='sports-search-form-main'>
                        <form className="sports-search-form">
                            <button type="submit" className="sport-search-submit" disabled>
                                <img src={Images.navbar.searchIcon} alt="Search Icon" />
                            </button>
                            <input onChange={handleSearchChange} value={searchTerm} type="text" name="search" className="sport-search-input" placeholder="search events..." autoFocus />
                        </form>
                    </div>

                    <div className='sports-sort'>
                        <SortingDropdownComponent
                            activeButton={sort.value}
                            onClick={handleSortChange}
                            buttons={Object.entries(soringButtons)}
                        />
                    </div>
                </div>
            </div>
            <div className='row cards-home g-3 justify-content-between'>
                {
                    events?.map((event) => {
                        return (
                            <Cards key={event._id} event={event} />
                        )
                    })
                }
                {/* <DummyCardsOne />
                <DummyCardsOne />
                <DummyCardsOne />
                <DummyCardsOne />
                <DummyCardsOne />
                <DummyCardsOne /> */}
            </div>
            {/* <Pagination /> */}
            <div className="pagination-icon mt-3">
                <PaginatedComponent
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={handlePageChange}
                />
            </div>

        </div>
    )
}
