
import React from 'react'
import './Cards.css'
import { Images } from '../Images/Images'
import RadialBarChart from '../RadialBarChart/RadialBarChart'
import { ButtonComponent, formatAndLowerPath, formatPathName, getUrlDetails } from '../../Utils/Helper/HelperFunction'
import { useLocation, useNavigate } from 'react-router-dom'

export const DummyCardsOne = ({ event }) => {
    console.log('event: ', event);
    const navigate = useNavigate()
    const location = useLocation();
    const { pageName, category } = getUrlDetails(location.pathname);

    return (
        <div onClick={() => navigate(`/${pageName}/${category}/event-detail/id-change-it-later`)} className='col-md-6 fix-width'>
            <div className='card-main'>
                <div className='card-main-one'>
                    <div className='image-div'>
                        <img src={Images.cards.imageOne} alt="card-image" />
                    </div>
                    <div>Who Will win today argument on passing the bill in the parliament?</div>
                </div>

                <div className='card-chance-bar-main'>
                    <div className='under'>
                        <div className='chance-para'>Donald Trump Winning</div>

                        <div className="chance-bar">
                            <RadialBarChart />
                            <div className='chances'>Chances</div>
                        </div>
                        <div className='bet-btn'>
                            <ButtonComponent
                                text={'BET'}
                            />
                        </div>
                    </div>
                    <div className='under'>
                        <div className='chance-para'>Donald Trump Winning</div>

                        <div className="chance-bar">
                            <RadialBarChart />
                            <div className='chances'>Chances</div>
                        </div>
                        <div className='bet-btn'>
                            <ButtonComponent
                                text={'BET'}
                            />
                        </div>
                    </div>
                    <div className='under'>
                        <div className='chance-para'>Donald Trump Winning</div>

                        <div className="chance-bar">
                            <RadialBarChart />
                            <div className='chances'>Chances</div>
                        </div>
                        <div className='bet-btn'>
                            <ButtonComponent
                                text={'BET'}
                            />
                        </div>
                    </div>
                </div>

                <div className='card-buttons'>
                    <button className='No'>No</button>
                    <button className='Yes'>Yes</button>
                </div>


                <div className='card-footer-main'>
                    <div className='card-footer-one'>
                        <div>
                            <img src={Images.cards.parking} alt="parking image" />
                        </div >
                        <div className='bet-count'>474.84K  Bet</div>
                    </div>

                    <div className='card-like-comment'>

                        <div className='comment-img'>
                            <img src={Images.cards.comment} alt="comment image" />
                        </div>
                        <div className='comment-txt'>23</div>
                        <div className='comment-img'>
                            <img src={Images.cards.star} alt="comment image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}