import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { CategoryButtonsComponent, ComingSoonComponent, formatPathName, SortingDropdownComponent } from '../../Utils/Helper/HelperFunction';
import Pagination from '../../Components/Pagination/Pagination';
import { Images } from '../../Components/Images/Images';
import './ActiveBets.css'
import { ActiveBetCards } from '../../Components/Cards/ActiveBetCards';
import { PATHS, SERVICES } from '../../config/Api_Url';
import GetRequest from '../../services/GetRequest';

export const ActiveBets = () => {


  const [activeBets, setActiveBets] = useState([]);
  const [category, setCategory] = useState({ key: 'ALL', value: 'All' });
  const [sort, setSort] = useState({ key: 'ALL', value: 'All' });
  const [searchTerm, setSearchTerm] = useState('');
  const buttons = {
    ALL: 'All',
    FOOTBALL: 'Football',
    CRICKET: 'Cricket',
    TENNIS: 'Tennis',
    BOXING: 'Boxing',
    BASKETBALL: 'Basketball',
    BASEBALL: 'Baseball'
  }
  const soringButtons = {
    ALL: 'All',
    NEW: 'New',
    TOP: 'Top',
    TRENDING: 'Trending',
    ENDING_SOON: 'Ending Soon'
  }

  const getActiveBets = async (status = 'active', page = 1) => {
    try {
      let params = { page: page, per_page: 10 }
      let activebets = await GetRequest(SERVICES.bettingService, `${PATHS.get_bets}/${status}`);      

      setActiveBets(activebets?.data?.data)
      // setPagination({
      //   currentPage: page,
      //   totalPages: events?.data.data.total_pages || 1,
      // });

    } catch (error) {
      console.log('getActiveBets error: ', error);

    }
  }


  useEffect(() => {
    getActiveBets();
  }, []);


  const handleCategoryChange = ({ key, value }) => {
    setCategory({ key, value });
  };
  const handleSortChange = ({ key, value }) => {
    setSort({ key, value });
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };  

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log('Searching for:', searchTerm);
  };
  return (
    <div className='mt-4 sports-main'>
      <div className=' sports-btn-dropsdown'>
        <div className='sports-btn'>
          <CategoryButtonsComponent onClick={(e) => handleCategoryChange(e)} buttons={Object.entries(buttons)} isActive={category.value} />
        </div>
        <div className='sports-grp-search-sort'>
          <div className='sports-search-form-main'>
            <form className="sports-search-form">
              <button type="submit" className="sport-search-submit" disabled>
                <img src={Images.navbar.searchIcon} alt="Search Icon" />
              </button>
              <input onChange={handleSearchChange} value={searchTerm} type="text" name="search" className="sport-search-input" placeholder="search events..." autoFocus />
            </form>
          </div>

          <div className='sports-sort'>
            <SortingDropdownComponent
              activeButton={sort.value}
              onClick={handleSortChange}
              buttons={Object.entries(soringButtons)}
            />
          </div>
        </div>
      </div>
      <div className='row cards-home g-3 justify-content-between'>
      {
          activeBets?.map((activeBet) => {
            return (
              <ActiveBetCards key={activeBet._id} activeBet={activeBet} />
            )
          })
        }
      {/* <ActiveBetCards />
      <ActiveBetCards />
      <ActiveBetCards />
      <ActiveBetCards />
      <ActiveBetCards />
      <ActiveBetCards /> */}
       
      </div>
      <Pagination />

    </div>
  )
}



