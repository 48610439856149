import React from 'react'
import './Footer.css'

export const Footer = () => {
    return (
        <div className='footer-main'>
            <span>All Rights Reserved @2024</span>
        </div>
    )
}
