import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { RoutesAndLayouts } from './Components/Routes&Layouts/RoutesAndLayouts';
import MyErrorBoundary from './Components/ErrorBoundry/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UseContext } from './Components/Context/UseContext';
// import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import auth0Config from './Utils/auth0-config';
import { Auth0Provider } from './Components/AuthProvider/AuthProvider';
// import 'rsuite/dist/rsuite.min.css';

function App() {
  // const { isAuthenticated, logout, isLoading } = useAuth0();
  // console.log(isAuthenticated,  isLoading);

  return (
    // <Auth0Provider
    //   domain={auth0Config.domain}
    //   clientId={auth0Config.clientId}
    //   redirectUri={auth0Config.redirectUri}
    //   audience={auth0Config.audience}
    //   authorizationParams={{
    //     redirect_uri: auth0Config.redirectUri
    //   }}
    // >
    <Auth0Provider > 
      <UseContext >
        <BrowserRouter>
          <RoutesAndLayouts>
            <MyErrorBoundary>
            </MyErrorBoundary>
          </RoutesAndLayouts >
          <ToastContainer />
        </BrowserRouter>
      </UseContext>

    </Auth0Provider>
  );
}

export default App;
