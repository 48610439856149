// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Cards/no-events.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.favourite-main {
    background: rgba(255, 255, 255, 1);
    padding: 20px 15px;
    border-radius: 15px;
}

.favourite>div>div {
    box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.04);
    box-shadow: -1px -1px 16px 0px rgba(0, 0, 0, 0.04);
}

.fav-srh {
    border: 1px solid rgba(169, 169, 169, 1);
    border-radius: 60px;
    position: relative;
}

.no-event-img {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
    /* Centers the image */
    background-size: contain;
    width: 100%;
    height: 400px;
    display: block;
    margin: 20px 0;
}

.no-event-under-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.no-event-under-main .head {
    font-family: Montserrat;
    font-size: var(--font-20px);
    font-weight: 700;
    text-align: center;
    color: rgba(51, 51, 51, 1);
}

.no-event-under-main .para {
    font-family: Montserrat;
    font-size: var(--font-14px);
    font-weight: 400;
    text-align: center;
    color: rgba(102, 102, 102, 1);
    margin-top: 10px;
}
.no-event-under-main .btn {
    width: 200px;
    margin: 15px auto;

}`, "",{"version":3,"sources":["webpack://./src/Pages/Favourite/Favourite.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gDAAgD;IAChD,kDAAkD;AACtD;;AAEA;IACI,wCAAwC;IACxC,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,2EAA2E;IAC3E,sBAAsB;IACtB,wBAAwB;IACxB,WAAW;IACX,aAAa;IACb,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,2BAA2B;IAC3B,gBAAgB;IAChB,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,uBAAuB;IACvB,2BAA2B;IAC3B,gBAAgB;IAChB,kBAAkB;IAClB,6BAA6B;IAC7B,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,iBAAiB;;AAErB","sourcesContent":[".favourite-main {\n    background: rgba(255, 255, 255, 1);\n    padding: 20px 15px;\n    border-radius: 15px;\n}\n\n.favourite>div>div {\n    box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.04);\n    box-shadow: -1px -1px 16px 0px rgba(0, 0, 0, 0.04);\n}\n\n.fav-srh {\n    border: 1px solid rgba(169, 169, 169, 1);\n    border-radius: 60px;\n    position: relative;\n}\n\n.no-event-img {\n    background: url('../../Assets/Cards/no-events.png') no-repeat center center;\n    /* Centers the image */\n    background-size: contain;\n    width: 100%;\n    height: 400px;\n    display: block;\n    margin: 20px 0;\n}\n\n.no-event-under-main {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.no-event-under-main .head {\n    font-family: Montserrat;\n    font-size: var(--font-20px);\n    font-weight: 700;\n    text-align: center;\n    color: rgba(51, 51, 51, 1);\n}\n\n.no-event-under-main .para {\n    font-family: Montserrat;\n    font-size: var(--font-14px);\n    font-weight: 400;\n    text-align: center;\n    color: rgba(102, 102, 102, 1);\n    margin-top: 10px;\n}\n.no-event-under-main .btn {\n    width: 200px;\n    margin: 15px auto;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
