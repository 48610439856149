import React, { useEffect, useState } from 'react'
import Crousel from '../../Components/Crousel/Crousel'
import { ButtonComponent, CategoryButtonsComponent, formatAndLowerPath, getUrlDetails, SortingDropdownComponent } from '../../Utils/Helper/HelperFunction'
import { Cards } from '../../Components/Cards/Cards'
import RadialBarChart from '../../Components/RadialBarChart/RadialBarChart'
import Pagination, { PaginatedComponent } from '../../Components/Pagination/Pagination'
import './Home.css'
import axios from 'axios'
import PostRequest from '../../services/PostRequest'
import { PATHS, SERVICES } from '../../config/Api_Url'
import GetRequest from '../../services/GetRequest'
import { Images } from '../../Components/Images/Images'
import { DummyCardsOne } from '../../Components/Cards/DummyCardsOne'
import { useLocation, useNavigate } from 'react-router-dom'

export const Home = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { pageName, category: urlCategory } = getUrlDetails(location.pathname);


  const [events, setEvents] = useState([]);
  const [category, setCategory] = useState({ key: 'ALL', value: 'All' })
  const [sort, setSort] = useState({ key: 'ALL', value: 'All' });
  const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1 });

  const handlePageChange = (pageNumber) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: pageNumber,
    }));
  };

  const buttons = {
    ALL: 'All',
    SPORT: 'Sports',
    ELECTION: 'Election',
    WEATHER: 'Weather',
    LATEST: 'Latest',
    TRENDING: 'Trending',
    TOP: 'Top',
    POLITICS: 'Politics',
    ENTERTAINMENT: 'Entertainment',
  }
  const soringButtons = {
    ALL: 'All',
    NEW: 'New',
    TOP: 'Top',
    TRENDING: 'Trending',
    ENDING_SOON: 'Ending Soon'
  }

  const getAllEvents = async (category = 'ALL', page = 1) => {
    try {
      let params = { page: page, per_page: 10 }
      let events = await GetRequest(SERVICES.eventService, `${PATHS.active_events}/${category}`, params);
      
      setPagination({
        currentPage: page,
        totalPages: events?.data.data.total_pages || 1,
      });
      setEvents(events?.data.data.events || [])
    } catch (error) {
      console.log('getAllEvents error: ', error);

    }
  }

  useEffect(() => {
    getAllEvents(category.key, pagination.currentPage);
  }, [category, pagination.currentPage]);



  const handleCategoryChange = ({ key, value }) => {
    setCategory({ key, value });
    navigate(`/${pageName}/${formatAndLowerPath(value)}`)
  };


  const handleSortChange = ({ key, value }) => {
    setSort({ key, value });
  };

  return (
    <>
      <Crousel />
      <div className=' sports-btn-dropsdown'>
        <div className='sports-btn'>
          <CategoryButtonsComponent
            onClick={(e) => handleCategoryChange(e)}
            buttons={Object.entries(buttons)}
            isActive={category.value}
          />
        </div>
        <div className='sports-grp-search-sort'>

          <div className='sports-sort'>
            <SortingDropdownComponent
              activeButton={sort.value}
              onClick={handleSortChange}
              buttons={Object.entries(soringButtons)}
            />
          </div>
        </div>
      </div>

      <div className='row  cards-home g-3 justify-content-between'>
        {
          events?.map((event) => {
            return (
              <Cards key={event._id} event={event} />
            )
          })
        }
      </div>
      <div className="pagination-icon mt-3">
        <PaginatedComponent
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  )
}

