import { RotatingLines } from "react-loader-spinner";
import { Images } from "../../Components/Images/Images";


export const formatPathName = (input) => {
    if (typeof input !== 'string') {
        return '';
    }

    let cleanedText = input.replace(/\//g, '');
    let formattedText = cleanedText.charAt(0).toUpperCase() + cleanedText.slice(1);
    return formattedText;
};
export const generateHamBurgerPath = (pageName, category, IsEventDetail) => {
    const formattedPageName = formatPathName(pageName);
    const formattedCategory = formatPathName(category);
    let hamBurgerPath = `${formattedPageName}`;

    if (formattedCategory) {
        hamBurgerPath += ` / ${formattedCategory}`;
    }

    if (IsEventDetail) {
        hamBurgerPath += ` / Event Detail`;
    }

    return hamBurgerPath;
};


export const formatAndLowerPath = (pathname) => {
    if (pathname === '/home') {
        return 'home';
    }

    return formatPathName(pathname).toLowerCase();
}
export const getTruncatedText = (string = '', from = 0, to = 200,) => {
    return string.slice(from, to);
}
export const ButtonComponent = ({ value, text, style, onClick, isActive, isDisabled }) => {
    return (<button disabled={isDisabled} value={value} onClick={onClick} className={`nav-btn ${isActive}`} style={style}>{text}</button>)
}

export const getUrlDetails = (url) => {
    try {
        const pathSegments = url.split('/').filter(Boolean);
        return {
            pageName: pathSegments[0],
            category: pathSegments[1],
            IsEventDetail: pathSegments[2] === 'event-detail' ? true : false
        }
    } catch (error) {
        console.error('Invalid URL:', error);
    }
}

export const CategoryButtonsComponent = ({ onClick = {}, buttons = [], isActive = '' }) => {

    let button = buttons.map(([key, value], index) => {
        let activeButton = isActive === value;
        return (
            <ButtonComponent
                isActive={activeButton ? 'active' : ''}
                key={index}
                text={value}
                style={{
                    background: activeButton ? 'rgba(65, 105, 225, 0.1)' : 'rgba(169, 169, 169, 0.35)',
                    border: 'none',
                    color: activeButton ? 'rgba(65, 105, 225, 1)' : ' rgba(51, 51, 51, 1)',
                    margin: '0 15px 0 0'
                }}
                onClick={() => onClick({ key, value })}
            />
        )
    })
    return (<div className='home-category-btns'>{button}</div>)
}

export const LoaderComponent = ({ isLoading }) => {
    return (
        <RotatingLines
            visible={isLoading}
            height="20"
            width="20"
            color="grey"
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperclassName=""
        />
    )
}

export const useSessionStorageData = () => {
    return (
        {
            googleAccessToken: sessionStorage.getItem('google-access-token') ? sessionStorage.getItem('google-access-token') : null,
            apiUserData: sessionStorage.getItem('api-user-data') ? JSON.parse(sessionStorage.getItem('api-user-data')) : null
        }
    )
}

export const ComingSoonComponent = ({ page = "" }) => {
    return (
        <div className="coming-soon-main">
            <span>{page} page will be coming soon.</span>
        </div>
    )
}
export const isMobileWidth = (window.screen.width < 768) ? true : false


export const SortingDropdownComponent = ({ buttons = [], onClick, activeButton = 'All' }) => {
    return (


        <div className="sorting-dropdown">
            <button className='sport-sort-btn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <div className='sport-sort-icon'>
                    <img src={Images.sortIcon} alt="sort-icon" />
                </div>
                <div className='sport-sort-txt'>Sort</div>
            </button>

            <ul className="dropdown-menu sort-menu p-4">
                <div className='sort-head'>
                    <span className='sort-by'>Sort By</span>
                    <span className='sort-cut-icon'>
                        <img src={Images.redCutIcon} alt="redcut-icon" />
                    </span>
                </div>
                <div className='sort-body'>
                    {
                        buttons.map(([key, value], index) => {
                            let _activeButton = activeButton === value
                            return (
                                <li onClick={() => onClick({ key, value })} className='sort-item' key={index}>
                                    <div className='sort-item-icon'>
                                        <img src={_activeButton ? Images.selectedIcon : Images.unSelectedIcon} alt="" />
                                    </div>
                                    <span className='sort-item-txt'>{value}</span>
                                </li>
                            )
                        })
                    }
                </div>
            </ul>
        </div>
    )

}

export const formatDate = (dateString) => {
    if (!dateString) {
        return
    }
    const cleanDateString = dateString.split('.')[0] + 'Z';

    const date = new Date(cleanDateString);

    if (isNaN(date.getTime())) {
        throw new RangeError("Invalid time value");
    }

    const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

    return formattedDate.replace(',', '').replace('at', ',');
}

export const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}